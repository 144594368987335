/**
 * Truncate a string to a specified length.
 * @param {string} text The input text string.
 * @returns {string} The truncated string.
 */
export const truncateString = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};
