"use client";

import { FC } from "react";
import { usePostHog } from "posthog-js/react";

import { ISummaryDetailed } from "./types";
import SummaryDetailedRemoveWhyFresh from "./experiments/SummaryDetailedRemoveWhyFresh";
import SummaryDetailedOriginal from "./archived/SummaryDetailedOriginal";
import SummaryDetailedPriceToMeat from "./experiments/SummaryDetailedPriceToMeat";

const SummaryDetailed: FC<ISummaryDetailed> = (props) => {

  const posthog = usePostHog();

  const isMeta = props.flowSlug === "fresh-food-new-user";
  if (!isMeta && props.isTrial && posthog.getFeatureFlag("retry-remove-why-fresh-from-suf-2") === "test") {
    return (<SummaryDetailedRemoveWhyFresh { ...props } />);
  }

  if (!isMeta && props.isTrial && posthog.getFeatureFlag("price-to-meat") === "test") {
    return (<SummaryDetailedPriceToMeat { ...props } />);
  }

  return (<SummaryDetailedOriginal { ...props } />);

};

export default SummaryDetailed;
