import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";
import clsx from "clsx";
import {
  CanMakePaymentResult,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  SetupIntent,
} from "@stripe/stripe-js";
import {
  PaymentRequestCompleteStatus,
} from "@stripe/stripe-js/types/stripe-js/payment-request";

import Button from "apps/website/components/base/Button/Button";
import Icon from "apps/website/components/base/Icon/Icon";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Form from "apps/website/components/form/Form/Form";
import { inputStyles } from "apps/website/components/form/Input/Input";
import Label from "apps/website/components/form/Label/Label";
import { DisplayState } from "@/constants/state";
import FormError from "apps/website/components/form-service/fields/Error";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { useAPI } from "apps/website/hooks/useAPI";
import {
  getActionTrackingInfo,
} from "apps/website/utils/tracking/getActionTrackingInfo";
import {
  getFlowFieldValuesForAction,
  useCatsStore,
  useCustomerStore,
  useDiscountStore,
  useNewFormsServiceStore,
} from "@./state";
import { CheckoutBasket, CheckoutStatus } from "@/primary-models";
import ProcessingPaymentModal
  from "apps/website/components/feature/Modal/ProcessingPaymentModal/ProcessingPaymentModal";
import { useFlow } from "apps/website/contexts/flow";
import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import { usePurchaseStore } from "libs/state/src/lib/stores/usePurchaseStore";
import { useDatadog } from "@auth/client-sdk-react";
import { PRODUCT_CATEGORIES } from "libs/form-utils/src/lib/product-categories";
import { ICheckoutV2SetupIntentData } from "@forms/schema";
import PayPalButton from "apps/website/components/feature/PayPalButton/PayPalButton";

import { processingModalPhraseMap } from "./CardDetailsFormCheckoutV2.map";

const cardInputClasses = `${inputStyles} flex items-center cursor-text [&>*]:w-full [&>*]:font-display`;
const cardInputOptions = { style: { base: { fontFamily: "GreedBold, sans-serif", fontWeight: "900", fontSize: "20px" } } };

const DEFAULT_QUICK_CHECKOUT_ERROR = "Something went wrong, please checkout manually below.";
const DEFAULT_CHECKOUT_ERROR = "Something went wrong, please try another card or check your details and try again.";

export type PaymentDetailsType = "default" | "quickCheckout";
export interface ICardDetailsFormCheckoutV2 {
  isDisabled?: boolean;
  flowSlug: string;
  formUsed: string,
  onSuccess(): void;
  paymentType?: PaymentDetailsType;
  price?: number;
}

const CardDetailsFormCheckoutV2: FC<ICardDetailsFormCheckoutV2> = ({
  isDisabled = false,
  flowSlug,
  formUsed,
  onSuccess,
  paymentType = "default",
  price,
}) => {
  const {
    FormActions: formsActionsAPI,
    Checkout: checkout,
  } = useAPI();

  const posthog = usePostHog();
  const datadog = useDatadog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);
  const { setCanUseQuickCheckout, setPurchaseInProgress } = usePurchaseStore();
  const { setFlowFieldValue } = useNewFormsServiceStore();

  const stripe = useStripe();
  const elements = useElements();
  const [ error, setError ] = useState<string | undefined>(undefined);
  const [ isCardNumberComplete, setIsCardNumberComplete ] = useState<boolean>(false);
  const [ isCVCComplete, setIsCVCComplete ] = useState<boolean>(false);
  const [ isExpiryComplete, setIsExpiryComplete ] = useState<boolean>(false);
  const [ isCardDetailsComplete, setIsCardDetailsComplete ] = useState<boolean>(false);
  const [ paymentState, setPaymentState ] = useState<DisplayState>(DisplayState.READY);
  const [ paymentRequest, setPaymentRequest ] = useState<PaymentRequest | null>(null);
  const [ paymentRequestResult, setPaymentRequestResult ] = useState<CanMakePaymentResult | null>(null);
  const [ quickCheckoutError, setQuickCheckoutError ] = useState<string | undefined>(undefined);
  const [ showPayPalButton, setShowPayPalButton ] = useState<boolean>(false);

  const customerStore = useCustomerStore();
  const { cats } = useCatsStore();
  const discountStore = useDiscountStore();

  const { flow } = useFlow();

  /** This will use formsActions to try to save a basket and start the checkout.
   * Several outcomes are possible:
   * 1. If all goes well, it will store the checkout data in a basket,
   * create a setupIntent and return the SetupIntent's clientSideSecret and the basket's ID
   * 2. If a checkout is already in progress for this user and has not timed out,
   * the existing basketId will be returned so that the checkout can be "resumed"
   * 3. If there is a problem creating the basket or the setupIntent, an error message is returned
   */
  const getSetupIntent = useCallback(async (): Promise<({
    success: true,
  } & ICheckoutV2SetupIntentData) | {
    success: false,
    message: string,
  }> => {

    const actionTrackingInfo = getActionTrackingInfo(cookies);
    const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);

    const discountCode = discountStore.discountCodes.find((discount) => discount.flowId === flowSlug)?.discountCode;
    const customerId = customerStore.customerIds.find((customer) => customer.flowId === flowSlug)?.customerId;

    if (customerId) {
      fieldSubmitValuesMap.set("customerId", {
        value: customerId,
        linkingId: "0",
      });
    } else {
      const message = `No customerId for flow ${flowSlug}`;
      datadog.logger.error(message, {
        flowSlug,
        discountCodes: [ ...discountStore.discountCodes ],
        customerIds: [ ...customerStore.customerIds ],
      });
      // TODO Might want to redirect to customer details page
      return {
        success: false,
        message,
      };
    }

    if (discountCode) {
      fieldSubmitValuesMap.set("discountCode", {
        value: discountCode,
        linkingId: "0",
      });
    } else {
      const message = `No discount for flow ${flowSlug}`;
      datadog.logger.warn(message, {
        flowSlug,
        discountCodes: [ ...discountStore.discountCodes ],
        customerIds: [ ...customerStore.customerIds ],
      });
    }

    const catsArr = cats.find((dc) => dc.flowId === flowSlug)?.catIds || [];
    fieldSubmitValuesMap.set("catIds", { value: catsArr });

    const actionName = flow.mappedFlow.product === "FRESH_TRIAL"
      ? "checkoutFreshNewCustomerV2"
      : "checkoutLitterNewCustomerV2";

    const setupIntentResult = await formsActionsAPI.performAction<ICheckoutV2SetupIntentData>(
      actionName,
      fieldSubmitValuesMap,
      flow.mappedFlow.product!,
      formUsed,
      undefined,
      posthog.featureFlags.getFlagVariants(),
      actionTrackingInfo,
      datadog.logger,
    );

    if (!setupIntentResult.success) {
      const result = {
        ...setupIntentResult,
        success: false,
        message: setupIntentResult.message || "There was a problem preparing your card data",
      } as const;
      datadog.logger.error(`SetupIntentResultFailure: ${result.message}`, {
        eventName: "SetupIntentResultFailure",
        result,
        "data-component": CardDetailsFormCheckoutV2.name,
      });
      return result;
    }

    datadog.logger.info("SetupIntentResultSuccess", {
      eventName: "SetupIntentResultSuccess",
      result: setupIntentResult,
      "data-component": CardDetailsFormCheckoutV2.name,
    });

    return {
      ...setupIntentResult.responseBody,
      success: true,
    };

  }, [
    posthog,
    datadog,
    cookies,
    customerStore.customerIds,
    discountStore.discountCodes,
    flow.mappedFlow.product,
    flowSlug,
    formUsed,
    formsActionsAPI,
  ]);

  /** @param paymentData If truthy, then the quick-checkout error is set.
   * If PaymentRequestPaymentMethodEvent, then it will also be completed
   * with the given paymentDataFailureReason failure reason or "fail" if
   * no reason is provided.
   * @param errorString The error that will be displayed.
   * @param paymentDataFailureReason The error to fail quick-checkout with.
   */
  const handleError: (
    paymentData?: PaymentRequestPaymentMethodEvent | boolean,
    errorString?: string,
    paymentDataFailureReason?: Exclude<PaymentRequestCompleteStatus, "success">
  ) => void = (paymentData, errorString, paymentDataFailureReason) => {
    datadog.logger.error(`Setting error state: ${errorString}`, {
      eventName: "HandleError",
      paymentData,
      errorString,
      paymentDataFailureReason,
      "data-component": CardDetailsFormCheckoutV2.name,
    });
    if (paymentData) {
      if (typeof paymentData !== "boolean") {
        paymentData.complete(paymentDataFailureReason || "fail");
      }
      setQuickCheckoutError(errorString || DEFAULT_QUICK_CHECKOUT_ERROR);
      setError(undefined);
      setPaymentState(DisplayState.READY);
    } else {
      setQuickCheckoutError(undefined);
      setError(errorString || DEFAULT_CHECKOUT_ERROR);
      setPaymentState(DisplayState.ERROR);
    }
    setPurchaseInProgress(false);
  };

  /** Will try to confirm a setupIntent, possibly starting a two-factor authentication flow
   * @param setupIntentClientSecret The secret of the SetupIntent we're currently trying to confirm
   * @param paymentData If provided, the data used for quick-checkout.
   * This function will set the result of the event, one way or another,
   * so after it has been called, you no longer need to worry about setting its status.
   * @return the confirmed setupIntent or undefined if the confirmation failed
   */
  const confirmPaymentData: (
    setupIntentClientSecret: string,
    paymentData?: PaymentRequestPaymentMethodEvent
  ) => Promise<SetupIntent | undefined> = async (
    setupIntentClientSecret,
    paymentData,
  ) => {
    if (!stripe) {
      datadog.logger.error("confirmPaymentData called with no stripe", {
        eventName: "ConfirmPaymentDataNoStripe",
        setupIntentClientSecret,
        paymentData,
        "data-component": CardDetailsFormCheckoutV2.name,
      });
      handleError(paymentData);
      return undefined;
    }
    // If this is a quick checkout
    if (paymentData) {
      let intent = await stripe.confirmCardSetup(
        setupIntentClientSecret,
        { payment_method: paymentData.paymentMethod.id },
        { handleActions: false },
      );
      // If we need to confirm the card, try to do that
      if (intent?.setupIntent?.status === "requires_action") {
        datadog.logger.info(`confirmPaymentData quickCheckout attempting to re-confirm card: ${intent.setupIntent.status}`, {
          eventName: "ConfirmPaymentDataQuickCheckoutReconfirm",
          setupIntentClientSecret,
          paymentData,
          intent,
          "data-component": CardDetailsFormCheckoutV2.name,
        });
        intent = await stripe.confirmCardSetup(setupIntentClientSecret);
      }
      // Check if the setupIntent was completed without error
      if (!intent || intent?.error || !intent?.setupIntent) {
        datadog.logger.error("confirmPaymentData quickCheckout failed", {
          eventName: "ConfirmPaymentDataQuickCheckoutFailed",
          setupIntentClientSecret,
          paymentData,
          "data-component": CardDetailsFormCheckoutV2.name,
        });
        handleError(paymentData, intent?.error?.message);
        return undefined;
      }
      // Else, the setupIntent completed without error
      const { setupIntent } = intent;
      // If the customer has not set up their card properly, we still consider that an error
      if (setupIntent.status !== "succeeded") {
        datadog.logger.error(`confirmPaymentData quickCheckout did not produce successful setupIntent: ${setupIntent.status}`, {
          eventName: "ConfirmPaymentDataQuickCheckoutBadStatus",
          setupIntentClientSecret,
          paymentData,
          intent,
          "data-component": CardDetailsFormCheckoutV2.name,
        });
        handleError(paymentData, `Card setup failed: ${setupIntent.status}`);
        return undefined;
      }
      // Else, all went well
      paymentData.complete("success");
      return setupIntent;

    }
    // Else, this is not a quick checkout
    const cardElement = elements?.getElement("cardNumber");
    // Check that the card exists
    if (!cardElement) {
      datadog.logger.error("Missing card element on payment details submission.", {
        eventName: "ConfirmPaymentDataCardNoCardElement",
        setupIntentClientSecret,
        cardElement,
        "data-component": CardDetailsFormCheckoutV2.name,
      });
      handleError();
      return undefined;
    }

    const intent = await stripe.confirmCardSetup(setupIntentClientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    // Check for errors
    if (!intent || intent?.error || !intent?.setupIntent) {
      datadog.logger.error("Setup intent failed", {
        eventName: "ConfirmPaymentDataCardFailed",
        setupIntentClientSecret,
        intent,
        "data-component": CardDetailsFormCheckoutV2.name,
      });
      handleError(undefined, intent?.error?.message);
      return undefined;
    }

    // Else, all went well
    return intent.setupIntent;

  };

  /** @param paymentData If provided, the data used for quick-checkout.
   */
  const handleSubmitInternal: (paymentData?: PaymentRequestPaymentMethodEvent) => Promise<void> =
    async (paymentData) => {
    // setPurchaseInProgress(true);
      setPaymentState(DisplayState.PROCESSING);

      const setup = await getSetupIntent();

      if (!setup.success) {
        handleError(paymentData, setup.message);
        return;
      }

      // Else, if the setup completed successfully
      const {
        setupIntentClientSecret,
        basketId,
        existingBasket,
      } = setup;

      try {
        if (existingBasket) {
          datadog.logger.debug(`Action returned existing basket ID '${existingBasket}', polling basket`, {
            eventName: "ActionReturnedExistingBasket",
            setup,
            "data-component": CardDetailsFormCheckoutV2.name,
          });
          const result: CheckoutBasket | null = await checkout.pollBasket(existingBasket);

          if (result === null) {
            datadog.logger.error(`Timed out while waiting for existing basket '${existingBasket}' to complete`, {
              eventName: "ExistingBasketTimedOut",
              setup,
              "data-component": CardDetailsFormCheckoutV2.name,
            });
            handleError(paymentData);
          } else if (result.status === CheckoutStatus.SUCCESS) {
            datadog.logger.info(`Existing basket '${existingBasket}' complete with status '${result.status}'`, {
              eventName: "ExistingBasketSuccessful",
              setup,
              result,
              "data-component": CardDetailsFormCheckoutV2.name,
            });
            setPaymentState(DisplayState.COMPLETE);
            // I think we don't want to unset this on success?
            // setPurchaseInProgress(false);
            onSuccess();
          } else {
            datadog.logger.error(`Existing basket '${existingBasket}' complete with status '${result.status}'`, {
              eventName: "ExistingBasketUnsuccessful",
              setup,
              result,
              "data-component": CardDetailsFormCheckoutV2.name,
            });
            handleError(paymentData);
          }
          return;
        }
        datadog.logger.debug("No existing basket, will attempt to create new one", {
          eventName: "ActionReturnedNoBasket",
          setup,
          paymentData,
          "data-component": CardDetailsFormCheckoutV2.name,
        });

        if (!setupIntentClientSecret) {
          datadog.logger.error("No client secret for setupIntent", {
            eventName: "ActionReturnedNoSecret",
            setup,
            "data-component": CardDetailsFormCheckoutV2.name,
          });
          handleError(paymentData);
          return;
        }

        const intent = await confirmPaymentData(
          setupIntentClientSecret,
          paymentData,
        );
        if (!intent) {
          return;
        }

        datadog.logger.info(`Setup intent succeeded, now polling basket '${basketId}'...`, {
          eventName: "SetupIntentSucceeded",
          setup,
          intent,
          "data-component": CardDetailsFormCheckoutV2.name,
        });

        setQuickCheckoutError(undefined);
        setError(undefined);
        setPaymentState(DisplayState.PROCESSING);

        const result: CheckoutBasket | null = await checkout.pollBasket(basketId);

        if (result === null) {
          datadog.logger.error(`Timed out while waiting for new basket '${basketId}' to complete`, {
            eventName: "NewBasketTimedOut",
            setup,
            intent,
            "data-component": CardDetailsFormCheckoutV2.name,
          });
          handleError(!!paymentData);
        } else if (result.status === CheckoutStatus.SUCCESS) {
          datadog.logger.info(`New basket '${basketId}' complete with status '${result.status}'`, {
            eventName: "NewBasketSuccessful",
            setup,
            intent,
            result,
            "data-component": CardDetailsFormCheckoutV2.name,
          });
          setPaymentState(DisplayState.COMPLETE);
          // I think we don't want to unset this on success?
          // setPurchaseInProgress(false);
          onSuccess();
        } else {
          datadog.logger.error(`New basket '${basketId}' complete with status '${result.status}'`, {
            eventName: "NewBasketUnsuccessful",
            setup,
            intent,
            result,
            "data-component": CardDetailsFormCheckoutV2.name,
          });
          handleError();
        }
      } catch (err) {
        datadog.logger.error("handleSubmit failed", {
          eventName: "HandeSubmitUnhandledException",
          setup,
          "data-component": CardDetailsFormCheckoutV2.name,
        }, err as Error);
        handleError(paymentData, (err as Error)?.message ?? undefined);
      }

    };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    datadog.logger.log("Normal-checkout starting", {
      eventName: "NormalCheckoutStarted",
      event,
      "data-component": CardDetailsFormCheckoutV2.name,
    });
    try {
      await handleSubmitInternal(undefined);
    } catch (cause) {
      datadog.logger.error("Unhandled exception during normal-checkout handleSubmitInternal", {
        eventName: "NormalCheckoutUnhandledExceptionSubmit",
        event,
        "data-component": CardDetailsFormCheckoutV2.name,
      }, cause as Error);
      handleError();
    }
  };

  const processingPhrases = useMemo<string[]>(() => processingModalPhraseMap[PRODUCT_CATEGORIES[flow.mappedFlow.product || "FRESH"]] || [], [ flow.mappedFlow.product ]);

  useEffect(() => {
    setIsCardDetailsComplete(isCardNumberComplete && isCVCComplete && isExpiryComplete);
  }, [ isCardNumberComplete, isCVCComplete, isExpiryComplete, discountStore ]);

  useEffect(() => {
    if (stripe) {
      const pr: PaymentRequest = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "KatKin Trial",
          amount: price || 0,
        },
        displayItems: [],
        requestPayerName: true,
        requestPayerEmail: false,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [ { id: "free", label: "Free Shipping", detail: "Free shipping by DPD", amount: 0 } ],
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          datadog.logger.debug("Quick-checkout available", {
            eventName: "QuickCheckoutSupportSupported",
            result,
            price,
            pr,
            "data-component": CardDetailsFormCheckoutV2.name,
          });
          setPaymentRequestResult(result);
          setPaymentRequest(pr);
          setCanUseQuickCheckout(true);
        } else {
          datadog.logger.warn("No data returned for quick-checkout support check", {
            eventName: "QuickCheckoutSupportNoData",
            result,
            "data-component": CardDetailsFormCheckoutV2.name,
          });
        }
      }).catch((paymentError) => datadog.logger.error("Could not check for quick-checkout support", {
        eventName: "QuickCheckoutSupportFailed",
        paymentError,
        "data-component": CardDetailsFormCheckoutV2.name,
      }, paymentError as Error));

    }
  }, [ stripe ]);

  useEffect(() => {
    if (stripe && paymentRequest && price) {
      datadog.logger.debug(`Updating paymentRequest price to ${price}`, {
        eventName: "QuickCheckoutPriceUpdated",
        price,
        "data-component": CardDetailsFormCheckoutV2.name,
      });
      paymentRequest.update({
        total: {
          label: "KatKin Trial",
          amount: price,
        },
      });
    }
  }, [ stripe, paymentRequest, price ]);

  /** @return true if there was an error with the address */
  const fillAddress = (event: PaymentRequestPaymentMethodEvent): boolean => {
    setPaymentState(DisplayState.PROCESSING);
    if ((event.shippingAddress &&
    event.shippingAddress.country && event.shippingAddress.country.toUpperCase() !== "GB") ||
     !event.shippingAddress ||
     !event.shippingAddress.postalCode ||
     !event.shippingAddress.addressLine?.length ||
     !event.payerPhone || !event.shippingAddress.city) {
      datadog.logger.warn("PaymentRequestInvalidAddress", {
        eventName: "PaymentRequestInvalidAddress",
        eventData: event,
        "data-component": CardDetailsFormCheckoutV2.name,
      });
      handleError(
        event,
        "There was a problem with your address, please fill in your address below.",
        "invalid_shipping_address",
      );
      return true;
    }

    datadog.logger.info("PaymentRequestValidAddress", {
      eventName: "PaymentRequestValidAddress",
      eventData: event,
      "data-component": CardDetailsFormCheckoutV2.name,
    });

    const addressList = event.shippingAddress.addressLine;
    const half = Math.ceil(addressList.length / 2);
    const addressLine1 = addressList.slice(0, half).reduce((acc, curr) => `${acc}${acc ? ", " : ""}${curr}`, "");
    const addressLine2 = addressList.slice(-half).reduce((acc, curr) => `${acc}${acc ? ", " : ""}${curr}`, "");

    const address = {
      line1: addressLine1 || "",
      line2: addressLine2 || "",
      city: event.shippingAddress.city || "",
      country: event.shippingAddress.country,
      postcode: event.shippingAddress.postalCode || "",
      phone: event.payerPhone || "",
    };
    setFlowFieldValue(flowSlug, "address", { displayValue: {
      billingAddress: address,
      shippingAddress: address,
    },
    submitValue: {
      billingAddress: address,
      shippingAddress: address,
    } });
    posthog.capture("user_action_custom", {
      type: "quick_checkout",
      data: {
        address: {
          billingAddress: address,
          shippingAddress: address,
        },
      },
    });
    datadog.logger.info("quick checkout address filled-in, proceeding with checkout", {
      eventName: "PaymentRequestAddressFilledIn",
      eventData: event,
      address,
      "data-component": CardDetailsFormCheckoutV2.name,
    });
    return false;
  };

  // const debouncedFillAddress = useCallback(debounce(fillAddress, 500), []);

  useEffect(() => {
    if (stripe && paymentRequest && paymentRequestResult) {
      paymentRequest.on("paymentmethod", async (event: PaymentRequestPaymentMethodEvent) => {
        datadog.logger.log("Quick-checkout starting", {
          eventName: "QuickCheckoutStarted",
          event,
          "data-component": CardDetailsFormCheckoutV2.name,
        });

        try {
          if (fillAddress(event)) {
            return;
          }
        } catch (cause) {
          datadog.logger.error("Unhandled exception during fillAddress", {
            eventName: "QuickCheckoutUnhandledExceptionFillAddress",
            event,
            "data-component": CardDetailsFormCheckoutV2.name,
          }, cause as Error);
          handleError();
          return;
        }

        try {
          await handleSubmitInternal(event);
        } catch (cause) {
          datadog.logger.error("Unhandled exception during quick-checkout handleSubmitInternal", {
            eventName: "QuickCheckoutUnhandledExceptionSubmit",
            event,
            "data-component": CardDetailsFormCheckoutV2.name,
          }, cause as Error);
          handleError();
        }
      });

      paymentRequest.on("cancel", async () => {
        datadog.logger.warn("Quick-checkout element dismissed (on cancel called)", {
          eventName: "QuickCheckoutCancelled",
          "data-component": CardDetailsFormCheckoutV2.name,
        });
        setPurchaseInProgress(false);
      });
    }
  }, [ paymentRequest, paymentRequestResult, stripe ]);

  useEffect(() => {
    if (posthog) {
      const flagValue = posthog.featureFlags.getFeatureFlag("paypal-painted-door-test");
      setShowPayPalButton(flagValue === "test");
    }
  }, [ posthog ]);

  return (
    <>
      { (paymentType === "quickCheckout") ? (
        <>
          { paymentRequest && (
            <Column>
              <PaymentRequestButtonElement
                options={{ paymentRequest }}
                onClick={() => setPurchaseInProgress(true)}
              />
            </Column>
          ) }

          { showPayPalButton && (
            <Column>
              <PayPalButton/>
            </Column>
          ) }

          { quickCheckoutError && (
            <Column>
              <Spacer size="md" />
              <FormError>{ quickCheckoutError }</FormError>
            </Column>
          ) }
        </>
      ) : (
        <Form action="" onSubmit={handleSubmit}>
          <fieldset>
            <Grid>
              <Column>
                <Label label="Card number" hideLabel={false} UUID="cardNumber" fontStyle="alternative">
                  <CardNumberElement
                    className={cardInputClasses}
                    options={cardInputOptions}
                    onChange={(event) => setIsCardNumberComplete(event.complete)}
                  />
                </Label>
              </Column>
              <Column spans={6}>
                <Label label="CVC" hideLabel={false} UUID="cardCvc" fontStyle="alternative">
                  <CardCvcElement
                    className={cardInputClasses}
                    options={cardInputOptions}
                    onChange={(event) => setIsCVCComplete(event.complete)}
                  />
                </Label>
              </Column>
              <Column spans={6}>
                <Label label="Expiry" hideLabel={false} UUID="cardExpiry" fontStyle="alternative">
                  <CardExpiryElement
                    className={cardInputClasses}
                    options={cardInputOptions}
                    onChange={(event) => setIsExpiryComplete(event.complete)}
                  />
                </Label>
              </Column>
              <>
                { error && (
                  <Column >
                    <FormError>{ error }</FormError>
                  </Column>
                ) }
              </>
            </Grid>
          </fieldset>
          <Spacer size="xl" />
          <Grid>
            <Column justify="center" align="center">
              <Button type="submit" disabled={!stripe || isDisabled || !isCardDetailsComplete} state={paymentState}>
                <span className="flex justify-center items-center"><Icon icon="padlock" color="inherit" size="xsmall" /><span className="ml-2">Place my order</span></span>
              </Button>
            </Column>
          </Grid>
          <Section
            theme="brand"
            size="sm"
            className={clsx(
              "fixed bottom-0 left-0 w-full p-4 z-30",
            )}>
            <Container
              className="flex items-center justify-center flex-col-reverse md:flex-row"
            >
              <Button type="submit" disabled={!stripe || isDisabled || !isCardDetailsComplete} state={paymentState} color="secondary">
                <span className="flex justify-center items-center"><Icon icon="padlock" color="inherit" size="xsmall" /><span className="ml-2">Place my order</span></span>
              </Button>
            </Container>
          </Section>
        </Form>
      ) }

      { (paymentState === DisplayState.COMPLETE || paymentState === DisplayState.PROCESSING) && (
        <ProcessingPaymentModal phrases={processingPhrases}
          processingComplete={paymentState === DisplayState.COMPLETE}
        />
      ) }

    </>
  );
};

export default CardDetailsFormCheckoutV2;
