import { FC } from "react";

import Image from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Table from "apps/website/components/feature/Table/Table";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { DisplayState } from "@/constants/state";

import { LoadingSpinner } from "../../../LoadingSpinner/LoadingSpinner";
import {
  footerCellStyle,
  leftCellStyle,
  rightCellStyle,
} from "../table/SummaryTableElements";
import SummaryDetailedRow from "../components/SummaryDetailedRow";
import { ISummaryDetailed } from "../types";
import SummaryDetailedFutureSaving from "../components/SummaryDetailedFutureSaving";

const SummaryDetailedOriginal:FC<ISummaryDetailed> = ({
  title,
  image,
  items,
  footer,
  children,
  state,
  reloadSummary,
  flowSlug,
}) => (

  <div data-component={ SummaryDetailedOriginal.name }>
    { image?.src && (
      <Image image={ image } alt={ title } />
    ) }
    <Table className={ "w-full border-collapse" }>
      <tr>
        <th
          className={ `text-left px-2 py-2 ${themeRootClassMap.dark}` }
          data-theme="dark"
          scope="col"
          colSpan={ 3 }
        >
          <div className="flex items-center justify-between">
            <Text tag="span" display="subtitle" size={ legacySizeCollectionMap.bodyLg }>{ title }</Text>
          </div>
        </th>
      </tr>
      <>
        { state === DisplayState.READY ? (
          <>

            <>
              { items?.map(((item, index) => (
                <SummaryDetailedRow
                  key={ index }
                  item={ item }
                  className={ (items.length % 2 === 0 && !children) ? "odd:bg-light-grey even:bg-white" : "even:bg-light-grey odd:bg-white" }
                  reloadSummary={ reloadSummary }
                  flowSlug={ flowSlug }
                />
              ))) }
            </>
            { children && (
              <tr className={ `h-auto ${items.length % 2 === 0 ? "bg-light-grey" : "bg-white"} ` }>
                <td className="text-left px-2 pb-2" colSpan={ 3 }>
                  { children }
                </td>
              </tr>
            ) }
            <>
              { footer?.map(((foot, index) => (
                <>
                  <tr key={ index } className={ `${index === footer.length - 1 ? themeRootClassMap.light : (items.length % 2 === 0) ? themeRootClassMap.light : themeRootClassMap["light-grey"]}` }>
                    <th colSpan={ 2 } scope="row" className={ `align-top ${footerCellStyle} ${leftCellStyle}` }>
                      <Text tag="span" display={ `${foot?.title?.bold ? "subtitle" : "default"}` } size={ (foot.type && [ "savings", "ongoing" ].includes(foot.type)) ? legacySizeCollectionMap.titleXs : legacySizeCollectionMap.titleSm } color={ foot.type === "savings" ? "success" : "default" }>{ foot?.title?.value }</Text>
                      <Text tag="span" display={ `${foot?.titleExtraDetail?.bold ? "subtitle" : "default"}` } size={ legacySizeCollectionMap.bodySm }>{ foot?.titleExtraDetail?.value }</Text>
                    </th>
                    <td colSpan={ 1 } className={ rightCellStyle }>
                      <div className="flex flex-row justify-end items-center">
                        <Text tag="span" className={ `text-right pr-3 ${foot?.preBody?.strike ? "line-through" : ""}` } display={ `${foot?.preBody?.bold ? "subtitle" : "default"}` } size={ { default: "3xs", md: "2xs" } }>{ foot?.preBody?.value }</Text>
                        <Text
                          tag="span"
                          className={ `text-right ${foot?.body?.strike ? "line-through" : ""}` }
                          display={ `${foot?.body?.bold ? "subtitle" : "default"}` }
                          size={
                            ((foot.type && [ "savings", "ongoing" ].includes(foot.type))) ?
                              legacySizeCollectionMap.titleXs : legacySizeCollectionMap.titleSm
                          }
                          color="default"
                        >{ foot?.body?.value }</Text>
                      </div>
                      <Text className={ `text-right pt-2 ${foot?.bodyExtra?.strike ? "line-through" : ""}` } display={ `${foot?.bodyExtra?.bold ? "subtitle" : "default"}` } size={ legacySizeCollectionMap.titleXs }>
                        { foot?.bodyExtra?.value }
                      </Text>
                    </td>

                  </tr>
                </>
              ))) }
            </>

          </>
        ) : (
          <tr>
            <td colSpan={ 2 } className="flex justify-center items-center py-8">
              <LoadingSpinner color="black" size={ 32 } />
            </td>
          </tr>
        ) }
      </>
    </Table>
    <SummaryDetailedFutureSaving cats={ items } />
  </div>
);

export default SummaryDetailedOriginal;
