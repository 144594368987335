import { FC, ReactNode } from "react";

import cx from "apps/website/utils/misc/cx";

interface SummaryTableElementProps {
  children: ReactNode;
  colSpan?: number;
  className?: string;
}

// Table elements
export const SummaryTH: FC<SummaryTableElementProps> = ({ children, className, colSpan }) => (
  <th colSpan={colSpan} className={cx("px-2 pb-2 lg:px-4 lg:pb-4", className)}>{ children }</th>
);

export const SummaryTD: FC<SummaryTableElementProps> = ({ children, className, colSpan }) => (
  <td colSpan={colSpan} className={cx("px-2 pb-2 lg:px-4 lg:pb-4 last:text-right", className)}>{ children }</td>
);

// Table cell styles
export const footerCellStyle = "pb-1";
const cellStyle = "px-2 py-2 lg:py-2";
export const leftCellStyle = `text-left w-[66.666%] ${cellStyle}`;
export const rightCellStyle = `text-right ${cellStyle} flex flex-col justify-end`;
