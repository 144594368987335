import { FC, useCallback, useMemo } from "react";

import { useNewFormsServiceStore } from "@./state";
import Image from "apps/website/components/base/Image/Image";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import { pluralise } from "apps/website/utils/misc/strings";
import Icon from "apps/website/components/base/Icon/Icon";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import Tag from "apps/website/components/base/Tag/Tag";
import Table from "apps/website/components/feature/Table/Table";
import TextControl from "apps/website/components/base/Text/TextControl/TextControl";
import { DisplayState } from "@/constants/state";

import { LoadingSpinner } from "../../../LoadingSpinner/LoadingSpinner";
import {
  SummaryDiscountCode,
} from "../../SummaryDiscountCode/SummaryDiscountCode";
import { SummaryTD, SummaryTH } from "../table/SummaryTableElements";
import { ISummaryDetailed } from "../types";
import SummaryDetailedImageDescription from "../components/SummaryDetailedImageDescription";

const SummaryDetailedRemoveWhyFresh: FC<ISummaryDetailed> = (props) => {
  const { setFlowFieldValue } = useNewFormsServiceStore();
  const { flowSlug, reloadSummary, discounts } = props;

  // This is taken from aother part of the code outside of the test
  const setCatPlanRemoved = useCallback((catId: string | undefined, state: boolean) => {
    if (!catId) {
      console.error("Tried to remove item with no catId", { catId });
      return;
    }
    setFlowFieldValue(flowSlug, "catPlanRemoved", {
      submitValue: state,
      displayValue: state,
    }, catId);
    reloadSummary();
  }, [ setFlowFieldValue, flowSlug, reloadSummary ]);

  const hasChickenSprinkles = useMemo<boolean>(() => (
    !!props.chickenSprinklesData?.quantity
  ), [ props.chickenSprinklesData ]);

  return (

    <div data-component={ SummaryDetailedRemoveWhyFresh.name }>
      { props.image?.src && (
        <Image image={ props.image } alt={ props.title } />
      ) }
      <div
        className={ `text-left px-2 py-2 mb-2 lg:mb-4 ${themeRootClassMap.dark}` }
        data-theme="dark"
      >
        <div className="flex items-center justify-between">
          <TextSubtitle tag="span" size={ 2 } color="default">Trial box {
            discounts?.discountPercentage ?
              <>({ discounts.discountPercentage }% off)</> :
              null
          }</TextSubtitle>
          <div data-theme="light-grey" className={ `rounded-full px-2 py-1 ${themeRootClassMap["light-grey"]}` }>
            <TextSubtitle tag="span" size={ 2 } color="default">{ props.costPerDay } / day</TextSubtitle>
          </div>
        </div>
      </div>

      <div className="mb-2 lg:mb-4">
        <Grid className="relative">
          <>
            <Column spans={ hasChickenSprinkles ? 6 : 12 } data-theme="light" className={ `rounded-md ${themeRootClassMap.light}` }>
              <Grid gap="none">
                <>
                  <Column spans={ !hasChickenSprinkles ? 6 : 12 }>
                    <Image image={ { src: "/images/dev/components/DetailedSummary/food.webp", width: 491, height: 255 } } alt="14 days of food" />
                  </Column>
                  { !hasChickenSprinkles ? (
                    <Column spans={6} justify="center" align="center">
                      <SummaryDetailedImageDescription
                        title={`14 days of food${pluralise("", " per cat", props.items.length)}. Perfectly portioned.`}
                        body="No need to feed anything else!"
                        align="default"
                      />
                    </Column>
                  ) : null }
                </>
              </Grid>
            </Column>
            { hasChickenSprinkles ? (
              <>
                <Column spans={ 6 } data-theme="light" className={ `rounded-md ${themeRootClassMap.light}` }>
                  <Image image={ { src: "/images/dev/components/DetailedSummary/nibbles.webp", width: 491, height: 255 }} alt="Nibbles" />
                </Column>
                <div
                  className="w-[36px] h-[36px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-light-grey"
                >
                  <Icon icon="plusHeavy" size="fit" />
                </div>
              </>
            ) : null }
          </>
        </Grid>
      </div>
      { hasChickenSprinkles ? (
        <div className="mb-2 lg:mb-4">
          <Grid>
            <Column spans={6}>
              <SummaryDetailedImageDescription
                title={`14 days of food${pluralise("", " per cat", props.items.length)}`}
                body="Perfectly portioned. No need to feed anything else!"
              />
            </Column>
            <Column spans={ 6 }>
              <SummaryDetailedImageDescription title={ `Chicken Sprinkles ${pluralise("", `(x${props.chickenSprinklesData?.quantity})`, props.chickenSprinklesData?.quantity || 1)}`} />
              <Spacer size="sm" />
              <div className="flex space-x-2 justify-center items-center" data-theme="light-grey">
                <TextBody size={2} tag="s" color="secondary">£{ props.chickenSprinklesData?.savings.toFixed(2) }</TextBody>
                <Tag theme="pink" className="p-1">Free gift</Tag>
              </div>
            </Column>
          </Grid>
        </div>
      ) : null }

      <Table className={ "w-full border-collapse bg-white" }>

        <>
          { props.state === DisplayState.READY ? (
            <>
              <tr>
                <SummaryTH className="pt-2 lg:pt-4">
                  <TextControl size={ 2 } className="text-green-dark">DPD delivery</TextControl>
                </SummaryTH>
                <SummaryTD className="pt-2 lg:pt-4"><Tag theme="pink" className="p-1">Free</Tag></SummaryTD>
              </tr>
              { props.discounts?.discountPercentage ? (
                <tr>
                  <SummaryTH>
                    <TextControl size={ 2 } className="text-green-dark" whitespace="noWrap">{ props.discounts.discountPercentage }% Discount on fresh food</TextControl>
                  </SummaryTH>
                  <SummaryTD>
                    <TextControl size={ 2 } className="text-green-dark" align="right">-£{ props.discounts.savings.toFixed(2) }</TextControl>
                  </SummaryTD>
                </tr>
              ) : null }
              { props.chickenSprinklesData?.quantity ? (
                <tr>
                  <SummaryTH>
                    <TextControl size={ 2 } className="text-green-dark" whitespace="noWrap">Savings on Chicken Sprinkles</TextControl>
                  </SummaryTH>
                  <SummaryTD>
                    <TextControl size={ 2 } className="text-green-dark" align="right">-£{ props.chickenSprinklesData.savings.toFixed(2) }</TextControl>
                  </SummaryTD>
                </tr>
              ) : null }
              <tr>
                <td colSpan={2} className="px-2 pb-2 lg:px-4 lg:pb-4">
                  <div className="bg-dark-grey h-0.5 w-full"></div>
                </td>
              </tr>
              <tr>
                <SummaryTH className="align-top">
                  <TextControl size={ 2 }>Total today</TextControl>
                </SummaryTH>
                <SummaryTD className="flex flex-col items-end">
                  <div>
                    <TextBody tag="s" size={ 2 }>£{ ((props.totalTodayNotDiscounted || 0) + (props.chickenSprinklesData?.savings || 0))?.toFixed(2) }</TextBody>&nbsp;
                    <TextControl tag="span" size={ 2 }>£{ props.totalTodayDiscounted?.toFixed(2) }</TextControl>
                  </div>
                  <Spacer size="sm" />
                  <div data-theme="light-grey" className={ `rounded-full w-fit px-2 py-1 ${themeRootClassMap["light-grey"]}` }>£{ props.pricePerMeal?.toFixed(2) } / meal</div>
                </SummaryTD>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={ 2 } className="flex justify-center items-center py-8">
                <LoadingSpinner color="black" size={ 32 } />
              </td>
            </tr>
          ) }
        </>
      </Table>
      <Spacer size="sm" />
      <div className="flex space-x-2 items-center justify-start">
        <div className="w-[22px] h-[22px]">
          <Icon icon="currency" size="fit" />
        </div>
        <SummaryDiscountCode flowId={ props.flowSlug }
          action="freshPlanSummaryNewCustomer"
          queryParam="discount_code"
          allowReferralCode={ true }
          defaultDiscountCode=""
        />
      </div>
      { props.items.map((item) => (
        <div key={item.catName}>
          { item?.removalData && (item.removalData.planRemoved ? (
            <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, false) }
              className="text-3xs text-green disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.addTitle }</button>
          ) : <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, true) }
            className="text-3xs text-red disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.removeTitle }</button>)
          }
        </div>
      )) }
    </div>
  );
};

export default SummaryDetailedRemoveWhyFresh;
