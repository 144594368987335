import {
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";

import { useNewFormsServiceStore } from "@./state";
import Image from "apps/website/components/base/Image/Image";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import { formatCatNames, pluralise } from "apps/website/utils/misc/strings";
import Icon from "apps/website/components/base/Icon/Icon";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import Tag from "apps/website/components/base/Tag/Tag";
import Table from "apps/website/components/feature/Table/Table";
import TextControl from "apps/website/components/base/Text/TextControl/TextControl";
import { DisplayState } from "@/constants/state";
import IconButton from "apps/website/components/base/IconButton/IconButton";
import SectionIngredientComparisonModal from "apps/website/components/section/SectionIngredientComparisonModal/SectionIngredientComparisonModal";
import {
  BrandWithoutExcluded,
} from "apps/website/components/feature/IngredientComparisonCard/brands";
import Container from "apps/website/components/layout/Container/Container";

import { LoadingSpinner } from "../../../LoadingSpinner/LoadingSpinner";
import {
  SummaryDiscountCode,
} from "../../SummaryDiscountCode/SummaryDiscountCode";
import { SummaryTD, SummaryTH } from "../table/SummaryTableElements";
import { ISummaryDetailed } from "../types";
import SummaryDetailedImageDescription from "../components/SummaryDetailedImageDescription";

const SummaryDetailedPriceToMeat: FC<ISummaryDetailed> = (props) => {
  const { setFlowFieldValue, getActiveFieldValues } = useNewFormsServiceStore();
  const { flowSlug, reloadSummary, discounts } = props;
  const [ isExpandedCats, setIsExpandedCats ] = useState<boolean>(false);
  const [ removedCatIds, setRemovedCatIds ] = useState<string[]>([]);
  const [ isExpandedSavings, setIsExpandedSavings ] = useState<boolean>(false);

  const originalKKPricePerDay = props.trialCostPerDayWithoutDiscount ?? "";
  const trialKKPricePerDay = props.trialCostPerDayWithDiscount ?? "";

  // Stores information about the cat food brands.
  const allCatFoodBrands = useMemo(
    () => getActiveFieldValues(props.flowSlug, "catFoodBrand") || [],
    [ props.flowSlug, getActiveFieldValues ],
  );

  // Retrieves and stores the first cat food brand name.
  const brandName = allCatFoodBrands.find((cat) => cat.key.linkingId === "1")?.data.submitValue
  || allCatFoodBrands.find((cat) => cat.data.submitValue)?.data.submitValue
  || undefined;

  // Stores information about each individual cat.
  const catItems = useMemo(() => {
    const allCatItems = props.items.filter((item) => item.catName);

    return allCatItems.filter((item) => {
      const linkingId = item.removalData?.linkingId;
      // If the item has a `linkingId`, check if it's in `removedCatIds` and exclude it.
      // If there's no `linkingId`, assume it's a single-cat plan and keep it visible.
      return linkingId ? !removedCatIds.includes(linkingId) : true;
    });
  }, [ props.items, removedCatIds ]);

  const isMultiCat = catItems.length > 1;

  const handleRemoveCat = (linkingId: string) => {
    setRemovedCatIds((prev) => [ ...prev, linkingId ]);
    void setCatPlanRemoved(linkingId, true);

    // If this was the last cat being displayed, collapse the section
    if (catItems.length === 1) {
      setIsExpandedCats(false);
    }
  };

  const handleAddCatBack = async () => {
    try {
      const linkingIds = removedItems
        .map((item) => item.removalData?.linkingId)
        .filter((id): id is string => Boolean(id));

      if (linkingIds.length > 0) {
        await setCatPlanRemoved(linkingIds, false);
        setRemovedCatIds((prev) => prev.filter((id) => !linkingIds.includes(id)));
      }
    } catch (error) {
      console.error("Error restoring plans:", error);
    }
  };

  // Stores information about all the savings.
  const savingsArray = [
    {
      name: props.discounts?.discountPercentage
        ? `${props.discounts.discountPercentage}% off fresh food`
        : "Discount",
      value: props.discounts?.savings || 0,
    },
    { name: isMultiCat ? `Chicken Sprinkles (x${catItems.length})` : "Chicken Sprinkles", value: props.chickenSprinklesData?.savings || 0 },
    { name: "DPD Delivery", value: props.deliveryPrice || 0 },
  ].filter((saving) => saving.value !== 0);

  const totalSavings = savingsArray.reduce((acc, curr) => acc + curr.value, 0).toFixed(2);

  // Stores information about all the removed cat plans.
  const removedItems = props.items.filter((item) => item?.removalData?.planRemoved);

  // This is taken from another part of the code outside of the test
  const setCatPlanRemoved = useCallback(async (catIds: string | string[] | undefined, state: boolean) => {
    if (!catIds || (Array.isArray(catIds) && catIds.length === 0)) {
      console.error("Tried to remove item with no valid catId", { catIds });
      return;
    }

    // Handle multiple IDs at once
    if (Array.isArray(catIds)) {
      await Promise.all(
        catIds.map((catId) => setFlowFieldValue(flowSlug, "catPlanRemoved", {
          submitValue: state,
          displayValue: state,
        }, catId)),
      );
      // Reload summary once all updates are done.
      reloadSummary();
    } else {
    // Handle single update
      setFlowFieldValue(flowSlug, "catPlanRemoved", {
        submitValue: state,
        displayValue: state,
      }, catIds);
      reloadSummary();
    }
  }, [ setFlowFieldValue, flowSlug, reloadSummary ]);

  const hasChickenSprinkles = useMemo<boolean>(() => (
    !!props.chickenSprinklesData?.quantity
  ), [ props.chickenSprinklesData ]);

  return (

    <div data-component={ SummaryDetailedPriceToMeat.name }>
      <div
        className={ `text-left px-2 py-2 mb-2 lg:mb-4 ${themeRootClassMap.dark}` }
        data-theme="dark"
      >
        <div className="flex items-center justify-between">
          <TextSubtitle tag="span" size={ 2 } color="default">Trial box {
            discounts?.discountPercentage ?
              <>({ discounts.discountPercentage }% off)</> :
              null
          }</TextSubtitle>
          <div data-theme="light-grey" className={ `rounded-full px-2 py-1 ${themeRootClassMap["light-grey"]}` }>
            <TextSubtitle tag="span" size={ 2 } color="default">{ props.trialCostPerDayWithDiscount }/day</TextSubtitle>
          </div>
        </div>
      </div>

      <div className="mb-2 lg:mb-4">
        <Grid className="relative">
          <>
            <Column spans={ hasChickenSprinkles ? 6 : 12 } data-theme="light" className={ `rounded-md ${themeRootClassMap.light}` }>
              <Grid gap="none">
                <>
                  <Column spans={ !hasChickenSprinkles ? 6 : 12 }>
                    <Image image={ { src: "/images/dev/components/DetailedSummary/food.webp", width: 491, height: 255 } } alt="14 days of food" />
                  </Column>
                  { !hasChickenSprinkles ? (
                    <Column spans={6} justify="center" align="center">
                      <SummaryDetailedImageDescription
                        title={`14 days of food${pluralise("", " per cat", props.items.length)}. Perfectly portioned.`}
                        body="No need to feed anything else!"
                        align="default"
                      />
                    </Column>
                  ) : null }
                </>
              </Grid>
            </Column>
            { hasChickenSprinkles ? (
              <>
                <Column spans={ 6 } data-theme="light" className={ `rounded-md ${themeRootClassMap.light}` }>
                  <Image image={ { src: "/images/dev/components/DetailedSummary/nibbles.webp", width: 491, height: 255 }} alt="Nibbles" />
                </Column>
                <div
                  className="w-[36px] h-[36px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-light-grey"
                >
                  <Icon icon="plusHeavy" size="fit" />
                </div>
              </>
            ) : null }
          </>
        </Grid>
      </div>
      { hasChickenSprinkles ? (
        <div className="mb-2 lg:mb-4">
          <Grid>
            <Column spans={6}>
              <SummaryDetailedImageDescription
                title={`14 days of food${pluralise("", " per cat", props.items.length)}`}
                body="Perfectly portioned. No need to feed anything else!"
              />
            </Column>
            <Column spans={ 6 }>
              <SummaryDetailedImageDescription title={ `Chicken Sprinkles${pluralise("", ` (x${props.chickenSprinklesData?.quantity})`, props.chickenSprinklesData?.quantity || 1)}`} />
              <Spacer size="sm" />
              <div className="flex space-x-2 justify-center items-center" data-theme="light-grey">
                <TextBody size={2} tag="s" color="secondary">£{ props.chickenSprinklesData?.savings.toFixed(2) }</TextBody>
                <Tag theme="pink">Free gift</Tag>
              </div>
            </Column>
          </Grid>
        </div>
      ) : null }
      { removedItems.length > 0 && (
        <div key="removed-plans" className="flex items-center space-x-1 my-4">
          <button
            onClick={handleAddCatBack}
          >
            <TextBody size={4} className="underline text-green-dark  opacity-100 lg:hover:opacity-70 transition-opacity duration-300" align="center">
              { `Add back ${pluralise("plan", "plans", removedItems.length)} for ${formatCatNames(removedItems.map((item) => item.removalData?.catName ?? "Unknown"))}` }
            </TextBody>
          </button>
        </div>
      ) }
      <Table className={ "w-full border-collapse bg-white" }>
        <>
          { props.state === DisplayState.READY ? (
            <>
              { /* Row for multi-cats toggle */ }
              <tr>
                { isMultiCat && (<SummaryTH colSpan={2} className="px-2 lg:px-4 py-1 w-full">

                  <div className="flex items-center justify-start w-full" onClick={() => setIsExpandedCats(!isExpandedCats)}>
                    <TextControl size={ 2 } className="cursor-pointer">See plans for all cats</TextControl>
                    <IconButton
                      icon="chevronDownHeavy"
                      label="Toggle extra detail"
                      size="xsmall"
                      buttonClassName="min-h-6"
                      onClick={() => setIsExpandedCats(!isExpandedCats)}
                      className={isExpandedCats ? "rotate-180 transition-transform duration-200 ease-in-out" : ""}
                    />
                  </div>

                </SummaryTH>) }
              </tr>

              { isExpandedCats && isMultiCat && catItems.map((item, index) => (
                <tr key={index}>
                  <SummaryTD colSpan={2} className="px-2 lg:px-4 pb-1">
                    <div className="flex items-center justify-between">
                      <TextBody size={3} className="!mb-0">{ item.catName }</TextBody>&nbsp;
                      <div className="flex items-center space-x-3">

                        <button onClick={() => item?.removalData?.linkingId &&
                           handleRemoveCat(item.removalData.linkingId)}>
                          <TextBody size={4} className="underline" align={"center"}>Remove Plan</TextBody>
                        </button>

                        <TextBody size={3} align={"center"}>{ item.bodyExtra?.value }</TextBody>
                      </div>
                    </div>
                  </SummaryTD>
                </tr>
              )) }

              { /* Row for total savings */ }
              <tr>
                <SummaryTH colSpan={2} className="px-2 lg:px-4 pb-1">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center" onClick={() => setIsExpandedSavings(!isExpandedSavings)}>
                      <TextControl size={2} className="text-green-dark cursor-pointer">Total savings</TextControl>
                      <IconButton
                        icon="chevronDownHeavy"
                        label="Toggle total savings"
                        size="xsmall"
                        buttonClassName="min-h-6 align-top"
                        onClick={() => setIsExpandedSavings(!isExpandedSavings)}
                        color="success"
                        className={isExpandedSavings ? "rotate-180 transition-transform duration-200 ease-in-out" : ""}
                      />
                    </div>

                    <TextControl size={2} className="text-green-dark">
                      { `-£${(totalSavings)}` }
                    </TextControl>

                  </div>

                </SummaryTH>
              </tr>
              { isExpandedSavings && savingsArray.map((saving, index) => (
                <tr key={index}>
                  <SummaryTD colSpan={2} className="pr-2 pl-4 lg:px-4 pb-1">
                    <div className="flex items-center justify-between ">
                      <TextBody size={3} className="!mb-0">{ saving.name }</TextBody>&nbsp;
                      <TextBody size={3}>{ `-£${saving.value.toFixed(2)}` }</TextBody>

                    </div>
                  </SummaryTD>
                </tr>
              )) }

              <tr>
                <td colSpan={2} className="px-2 pb-2 lg:px-4 lg:pb-4">
                  <div className="bg-dark-grey h-0.5 w-full"></div>
                </td>
              </tr>
              <tr>
                <SummaryTH className="align-top">
                  <TextControl size={ 2 }>Total today</TextControl>
                </SummaryTH>
                <SummaryTD className="flex flex-col items-end">
                  <div>
                    <TextBody tag="s" size={ 2 }>£{ ((props.totalTodayDiscounted || 0) + (+totalSavings)).toFixed(2) }</TextBody>&nbsp;
                    <TextControl tag="span" size={ 2 }>£{ props.totalTodayDiscounted?.toFixed(2) }</TextControl>
                  </div>
                  <Spacer size="sm" />
                  <div data-theme="light-grey" className={ `rounded-full w-fit px-2 py-1 ${themeRootClassMap["light-grey"]}` }>£{ props.pricePerMeal?.toFixed(2) }/meal</div>
                </SummaryTD>
              </tr>

            </>) : (
            <tr>
              <td colSpan={ 2 } className="flex justify-center items-center py-8">
                <LoadingSpinner color="black" size={ 32 } />
              </td>
            </tr>
          ) }
        </>
      </Table>

      { originalKKPricePerDay && trialKKPricePerDay && (
        <Container padding="none">

          <SectionIngredientComparisonModal
            brand={brandName as BrandWithoutExcluded}
            originalKKPricePerDay={originalKKPricePerDay}
            trialKKPricePerDay={trialKKPricePerDay}
            numberOfCats={catItems.length}
            containerClassName="md:justify-self-end"
          />
        </Container>

      ) }

      <Spacer size="sm" />
      <div className="flex space-x-2 items-center justify-start">
        <div className="w-[22px] h-[22px]">
          <Icon icon="currency" size="fit" />
        </div>
        <SummaryDiscountCode flowId={ props.flowSlug }
          action="freshPlanSummaryNewCustomer"
          queryParam="discount_code"
          allowReferralCode={ true }
          defaultDiscountCode=""
        />
      </div>

    </div>
  );
};

export default SummaryDetailedPriceToMeat;
