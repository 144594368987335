import React, { useCallback, useState } from "react";

import { useNewFormsServiceStore } from "@./state";
import Text from "apps/website/components/base/Text/Text";
import IconButton from "apps/website/components/base/IconButton/IconButton";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import { leftCellStyle, rightCellStyle } from "../table/SummaryTableElements";
import { ISummaryDetailedRow } from "../types";

const SummaryDetailedRow = ({ item, className, reloadSummary, flowSlug }: ISummaryDetailedRow) => {
  const { setFlowFieldValue } = useNewFormsServiceStore();
  const [ isExpanded, setIsExpanded ] = useState<boolean>(false);
  const setCatPlanRemoved = useCallback((catId: string | undefined, state: boolean) => {
    if (!catId) {
      console.error("Tried to remove item with no catId", { item });
      return;
    }
    setFlowFieldValue(flowSlug, "catPlanRemoved", {
      submitValue: state,
      displayValue: state,
    }, catId);
    reloadSummary();
  }, [ setFlowFieldValue, flowSlug, reloadSummary, item ]);

  return (
    <tr
      className={ `h-auto ${className}` }>
      <th scope="row" colSpan={ 2 } className={ leftCellStyle }>
        <div className="flex justify-start items-center w-full">
          <Text className={ `${item?.title?.strike ? "line-through" : ""}` } tag="span" size={ legacySizeCollectionMap.bodySm }>
            { item?.title?.value }
          </Text>
          { item?.titleExtraDetail && (
            <IconButton icon={ isExpanded ? "arrowUp" : "arrowDown" } label="Toggle extra detail" size="2xsmall"
              onClick={ () => setIsExpanded(!isExpanded) } />
          ) }
        </div>
        { isExpanded && item?.titleExtraDetail && (
          <Text className={ `${item.titleExtraDetail.strike ? "line-through" : ""}` }
            size={ { default: "3xs", md: "2xs" } }>{ item?.titleExtraDetail.value }</Text>
        ) }
        { item?.removalData && (item.removalData.planRemoved ? (
          <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, false) }
            className="text-3xs text-green disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.addTitle }</button>
        ) : <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, true) }
          className="text-3xs text-red disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.removeTitle }</button>)
        }
      </th>
      <td colSpan={ 1 } className={ rightCellStyle }>
        <div className="flex flex-row justify-end items-center">
          <Text tag="span" className={ `text-right pr-3 ${item?.preBody?.strike ? "line-through" : ""}` } display={ `${item?.preBody?.bold ? "subtitle" : "default"}` } size={ { default: "3xs", md: "2xs" } }>{ item?.preBody?.value }</Text>
          <Text
            tag="span"
            className={ `text-right ${item?.body?.strike ? "line-through" : ""}` }
            size={ legacySizeCollectionMap.bodySm }
            display={ `${item?.body?.bold ? item?.body?.uppercase ? "title" : "subtitle" : "default"}` }
            color={ item.body?.highlight ? "success" : "default" }
          >{ item?.body?.value }</Text>
        </div>
        <Text className={ `text-right ${item?.bodyExtra?.strike ? "line-through" : ""}` }
          display={ `${(item?.bodyExtra?.bold) ? "subtitle" : "default"}` } size={ legacySizeCollectionMap.titleSm }>
          { item?.bodyExtra?.value }
        </Text>
      </td>
    </tr>
  );
};

export default SummaryDetailedRow;
