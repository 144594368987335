import { FC, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { useCookies } from "react-cookie";

import {
  FormServiceIconTextList,
} from "apps/website/components/form-service/fields/specialist/FormIconTextList";
import {
  ActionCTAField,
  FieldName,
  FieldsetComponentTogglableDefaultState,
  INonFillableField,
  PaymentDetailsField,
  ReviewDetailsField,
  ReviewFormDetailsField,
} from "@forms/schema";
import { useCustomerStore } from "libs/state/src/lib/stores/useCustomerStore";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import {
  getFieldValue,
  getFieldValues,
  getFlowFieldValuesForInterpolation,
} from "@./state";
import { appendQueryparams } from "apps/website/utils/misc/merge-query";
import usePurchase from "libs/state/src/lib/stores/usePurchaseStore";
import {
  instanceOfAddProduct,
} from "apps/forms-structure/src/app/utils/forms-schema-type-guards";
import {
  FlowDataStructureResponse,
} from "apps/forms-structure/src/app/dto/FormDataStructureResponse.dto";
import { useQueryParams } from "apps/website/hooks/useQueryParams";
import { RC_PURCHASED } from "apps/website/trackers/triggers/handlers/gtm";

import { FormServiceCTA } from "./CTA";
import { FormStructurePaymentDetails } from "./specialist/PaymentDetails";
import { FormStructureSummary } from "./specialist/Summary";
import {
  FormServiceRecipesHighlightingRecipesTest,
} from "./specialist/RecipesHighlightingRecipesTest";
import { FormServiceAddProductCardList } from "./specialist/AddProductCardList";
import { ReviewDetails } from "./specialist/ReviewDetails";
import { ReviewFormDetails } from "./specialist/ReviewFormDetails";
import { FormServiceAddProduct } from "./specialist/AddProduct";
import { FormServiceSurvey } from "./specialist/Survey";
import {
  FormServiceCatSummaryHighlightingRecipesTest,
} from "./specialist/CatSummaryHighlightingRecipesTest";
import { CatSummaryC } from "./specialist/cat-summary-c/CatSummaryC";
import FreshProductCarousel from "./specialist/fresh-product-carousel/FreshProductCarousel";
import ScrollingBanner from "./specialist/scrolling-banner/ScrollingBanner";

interface INonFillableFieldProps {
  field: INonFillableField;
  linkingId: string;
  next(forceNext: boolean | undefined): void;
  flow: FlowDataStructureResponse;
  canContinue: boolean;
  fieldsetState?: FieldsetComponentTogglableDefaultState;
}

export const NonFillableField: FC<INonFillableFieldProps> = ({
  field,
  linkingId,
  next,
  flow,
  canContinue,
  fieldsetState,
}) => {
  const { customerIds } = useCustomerStore();
  const catNames = useMemo(() => getFieldValues(flow.slug, "catName"), []);
  const catGenders = useMemo(() => getFieldValues(flow.slug, "catSex"), []);
  const deliveryDate = useMemo(() => getFieldValue(flow.slug, "freshDeliveryDate", "0"), []);
  const { setPurchaseInProgress } = usePurchase();
  const { chainQueryParamsToUrl } = useQueryParams();
  const [
    ,
    setCookie,
  ] = useCookies();

  const onSuccessRedirect = useCallback(async (
    url: string,
    katkinCustomerId: string,
    signUpRoute: string,
    deliveryDateQueryParam: string,
    allCatGenders: string[],
    allCatNames: string[],
    flowSlug: string,
  ) => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("katkinCustomerId", katkinCustomerId);
      queryParams.append("signUpRoute", signUpRoute);
      queryParams.append("deliveryDate", deliveryDateQueryParam);
      allCatGenders.forEach((x) => queryParams.append("allCatGenders", x));
      allCatNames.forEach((x) => queryParams.append("allCatNames", x));
      queryParams.append("flow", flowSlug);
      const destination = appendQueryparams(url, queryParams);
      setCookie(RC_PURCHASED, "true", { path: "/" });
      window.location.href = destination;
    } catch (error) {
      setPurchaseInProgress(false);
      console.log("Failed to navigate to success page", error);
    }
  }, []);

  if (field.component === "scrolling_banner") {
    return <ScrollingBanner linkingId={linkingId} />;
  }

  if (field.component === "fresh_product_carousel") {
    return <FreshProductCarousel
      values={ getFlowFieldValuesForInterpolation(flow.slug, linkingId, "GET_RELATED") }
      linkingId={ linkingId }
    />;
  }

  if (field.component === "cat_summary_highlight_recipes_test") {
    return <FormServiceCatSummaryHighlightingRecipesTest
      values={ getFlowFieldValuesForInterpolation(flow.slug, linkingId, "GET_RELATED") }
      linkingId={ linkingId }
      flowSlug={ flow.slug }
    />;
  }

  if (field.component === "cat_summary_b") {
    return <FormServiceCatSummaryHighlightingRecipesTest
      values={ getFlowFieldValuesForInterpolation(flow.slug, linkingId, "GET_RELATED") }
      linkingId={ linkingId }
      flowSlug={ flow.slug }
    />;
  }

  if (field.component === "cat_summary_c") {
    return <CatSummaryC
      values={ getFlowFieldValuesForInterpolation(flow.slug, linkingId, "GET_RELATED") }
      linkingId={ linkingId }
      flowSlug={ flow.slug }
    />;
  }

  if (field.component === "survey_cancellation") {
    return <FormServiceSurvey
      survey="cancellation"
      flowSlug={ flow.slug }
    />;
  }
  if (field.component === "forms_CTA") {
    return <FormServiceCTA
      field={ field as unknown as ActionCTAField }
      flowId={ flow.slug }
      next={ next }
    />;
  } if ([ "quick_checkout", "payment_details" ].includes(field.component)) {
    return <>
      <FormStructurePaymentDetails
        flowId={ flow.slug }
        field={ field as unknown as PaymentDetailsField }
        onError={ () => console.error("An error with payment!") }
        onSuccess={ () => onSuccessRedirect(
          flow.mappedFlow.flow_success_redirect ? chainQueryParamsToUrl(flow.mappedFlow.flow_success_redirect?.url) : "",
          customerIds
            .find((ci) => ci.flowId === flow.slug)?.customerId ?? "",
          "formService",
          dayjs(deliveryDate?.data?.submitValue as string).format("YYYY-MM-DD"),
          catGenders.map((x) => x.data.submitValue as string),
          catNames.map((x) => x.data.submitValue as string),
          flow.slug,
        ) }
        paymentType={ field.component === "quick_checkout" ? "quickCheckout" : "default" }
        isSectionValid={ canContinue }
        isDisabled={ fieldsetState === "closed" }
      />
    </>;
  } if (field.component === "form_icon_text_list") {
    return <FormServiceIconTextList
      title={ interpolateString(field.title ?? "", getFlowFieldValuesForInterpolation(flow.slug, linkingId, "GET_ALL_WITH_REMOVED"), Interpolator.FRONTEND) }
      body={ interpolateString(field.body ?? "", getFlowFieldValuesForInterpolation(flow.slug, linkingId, "GET_ALL_WITH_REMOVED"), Interpolator.FRONTEND) }
      items={
        field.items?.[0].items?.map(
          (item) => ({
            image: getStoryBlokImage(item.image, true, ImageMax.HALF_CONTAINER_SM),
            title: item.title,
            body: item.body,
            titleSize: "xs",
          }),
        ) ?? [] }
      columns={ field.items?.[0].columns }
    />;
  } if (field.component === "summary") {
    return <FormStructureSummary
      title={ field.title }
      image={ field.image ? getStoryBlokImage(field.image, true, ImageMax.CONTAINER_MD) : undefined }
      child={ field.child }
      defaultDiscountCode={ field.default_discount_code }
      isExpandedByDefault={ field.is_expanded_by_default }
      canToggle={ field.can_toggle }
      flowId={ flow.slug }
      action={ field.action }
      allowReferralCode={ field.allow_referral }
      discountCodeQueryParam={ field.query_parameter }
      sendCheckoutInitiatedEvent={ field.send_checkout_initiated_event }
    />;
  } if (field.component === "pick_recipes") {
    return <FormServiceRecipesHighlightingRecipesTest
      data-testid={ `recipes[${linkingId}]` }
      totalQuantity={ field.total_quantity }
      seeMoreText={ field.see_more_text }
      seeLessText={ field.see_less_text }
      selectedRecipesTheme={ field.recommended_recipes_theme }
      cta={ field.cta?.[0] }
      action="recipesForCat"
      flowSlug={ flow.slug }
      next={ next }
      availableRecipes={ field
        .recipe_quantities
        .map((rec) => ({
          defaultQuantity: rec.default_quantity,
          image: getStoryBlokImage(rec.image, true, ImageMax.HALF_CONTAINER_SM),
          description: rec.description,
          name: rec.name,
          internalName: rec.internal_name,
        })) }
      linkingId={ linkingId }
      hideSuccessMessage={ field.hide_success_message }
    />;
  } if (field.component === "pick_recipes_highlight_recipes_test") {
    return <FormServiceRecipesHighlightingRecipesTest
      data-testid={ `recipes[${linkingId}]` }
      totalQuantity={ field.total_quantity }
      seeMoreText={ field.see_more_text }
      seeLessText={ field.see_less_text }
      selectedRecipesTheme={ field.recommended_recipes_theme }
      cta={ field.cta?.[0] }
      action="recipesForCat"
      flowSlug={ flow.slug }
      availableRecipes={ field
        .recipe_quantities
        .map((rec) => ({
          defaultQuantity: rec.default_quantity,
          image: getStoryBlokImage(rec.image, true, ImageMax.HALF_CONTAINER_SM),
          description: rec.description,
          name: rec.name,
          internalName: rec.internal_name,
        })) }
      linkingId={ linkingId }
      hideSuccessMessage={ field.hide_success_message }
    />;
  } if (field.component === "form_add_product_card_list") {
    return <FormServiceAddProductCardList
      field={ field }
      flowId={ flow.slug }
      linkingId={ linkingId }
      next={ () => next(undefined) }
    />;
  } if (field.component === "review_details") {
    return <ReviewDetails
      flowSlug={ flow.slug }
      label={ (field as ReviewDetailsField).label }
      field={ (field as ReviewDetailsField).field }
      fieldName={ (field as ReviewDetailsField).field_name as FieldName }
      linkingId={ linkingId }
      action={ (field as ReviewDetailsField).update_action }
      intialValue={ (field as ReviewDetailsField).value || "Current details" } />;
  } if (field.component === "review_form_details") {
    return <ReviewFormDetails
      flowSlug={ flow.slug }
      label={ (field as ReviewFormDetailsField).label }
      fieldNames={ (field as ReviewFormDetailsField).field_name as FieldName[] }
      linkingId={ linkingId }
      url={ (field as ReviewFormDetailsField).url.url } />;
  }
  if (instanceOfAddProduct(field)) {
    return <FormServiceAddProduct
      field={ field }
      flowId={ flow.slug }
      linkingId={ linkingId }
      next={ () => next(undefined) }
    />;
  }

  return null;
};
