export enum BrandEnum {
  FELIX = "FELIX",
  WHISKAS = "WHISKAS",
  GOURMET = "GOURMET",
  SHEBA = "SHEBA",
  ROYAL_CANIN = "ROYAL_CANIN",
  PURINA_ONE = "PURINA_ONE",
  APPLAWS = "APPLAWS",
  ALMO_NATURE = "ALMO_NATURE",
  AVA = "AVA",
  BLINK = "BLINK",
  BURNS = "BURNS",
  CARNILOVE = "CARNILOVE",
  CRAVE = "CRAVE",
  EDGARD_COOPER = "EDGARD_COOPER",
  ENCORE = "ENCORE",
  FERINGA = "FERINGA",
  FRESHPET = "FRESHPET",
  GO_CAT = "GO_CAT",
  HARRINGTONS = "HARRINGTONS",
  HILLS = "HILLS",
  IAMS = "IAMS",
  JAMES_WELLBELOVED = "JAMES_WELLBELOVED",
  LILYS_KITCHEN = "LILYS_KITCHEN",
  MEOWING_HEADS = "MEOWING_HEADS",
  NATURES_MENU = "NATURES_MENU",
  ORIJEN = "ORIJEN",
  PURR_MIAOW = "PURR&MIAOW",
  REPUBLIC_OF_CATS = "REPUBLIC_OF_CATS",
  SCRUMBLES = "SCRUMBLES",
  SUPERMARKET_BRAND = "SUPERMARKET_BRAND",
  THRIVE = "THRIVE",
  TOMOJO = "TOMOJO",
  UNTAMED = "UNTAMED",
  NATURAL_INSTINCT = "NATURAL_INSTINCT",
  NUTRIMENT = "NUTRIMENT",
  PURRFORM = "PURRFORM",
}

export const BrandEnumValues = Object.values(BrandEnum) as BrandEnum[];

export type Brand = typeof BrandEnum[keyof typeof BrandEnum];

export type ExcludedBrands = "NATURAL_INSTINCT" | "NUTRIMENT" | "PURRFORM";
export type BrandWithoutExcluded = Exclude<Brand, ExcludedBrands>;
