import React, { FC } from "react";

import { Align } from "apps/website/components/base/Text/Text.map";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

const SummaryDetailedImageDescription: FC<{ title: string, body?: string, align?: Align }> = ({ title, body, align = "center" }) => (
  <div className="flex flex-col justify-center items-center">
    <TextSubtitle size={ 2 } align={ align } whitespace="balance">{ title }</TextSubtitle>
    { body ? (
      <>
        <Spacer size="sm" />
        <TextBody size={ 3 } align={ align } whitespace="balance" className="w-full mb-0">{ body }</TextBody>
      </>
    ) : null }
  </div>
);

export default SummaryDetailedImageDescription;
