"use client";

import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import clsx from "clsx";
import Link from "next/link";

import {
  getFieldValue,
  getFieldsetState,
  getFlowFieldValuesForInterpolation,
  getRepeatedFieldsArray,
  isNonFillableField,
  showField,
  showFieldset,
  useDiscountStore,
  useNewFormsServiceStore,
} from "@./state";
import { IFlowContext } from "apps/website/contexts/flow";
import { useReturnToPath } from "apps/website/hooks/useReturnToPath";
import usePurchase from "libs/state/src/lib/stores/usePurchaseStore";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import {
  ActionCTAField,
  AddressValue,
  FillableFieldComponent,
  CatLifeStage,
} from "@forms/schema";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  isFieldset,
  isComponentSet,
} from "apps/forms-structure/src/app/utils/forms-schema-type-guards";
import { useQueryParams } from "apps/website/hooks/useQueryParams";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";
import { getStoryBlokLink } from "apps/website/utils/storyblok/links";
import { useAPI } from "apps/website/hooks/useAPI";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { ColumnSize, fieldSpanObjectMap } from "apps/website/maps/Form.map";

import FlowBanner from "../flows/FlowBanner";
import Main from "../../layout/Main/Main";
import Container from "../../layout/Container/Container";
import { ProgressSteps } from "../flows/ProgressSteps";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Section from "../../layout/Section/Section";
import Spacer from "../../layout/Spacer/Spacer";
import CatDivider from "../../feature/CatDivider/CatDivider";
import Image from "../../base/Image/Image";
import Information from "../../form/Information/Information";
import Grid from "../../layout/Grid/Grid";
import { Field, FormComponentName } from "../fields/Field";
import { FieldsetFooter } from "../fieldsets/FieldsetFooter";
import Column from "../../layout/Column/Column";
import { FlowContinue } from "../flows/navigation/FlowContinue";
import { FlowBack } from "../flows/navigation/FlowBack";
import LoadingPopover from "../../feature/LoadingPopover/LoadingPopover";
import Icon from "../../base/Icon/Icon";
import { AlertPrompt } from "../../feature/AlertPrompt/AlertPrompt";
import TextBody from "../../base/Text/TextBody/TextBody";
import { NonFillableField } from "../fields/NonFillableField";
import { IconSvg } from "../../base/Icon/Icon.map";

import { Fieldset } from "./layouts/FieldsetLayout";
import { SectionComponents } from "./SectionComponents";

const FormsContent: FC<IFlowContext> = ({
  back,
  canContinue,
  currentForm,
  currentSection,
  currentSectionIndex,
  flow,
  next,
  sections,
  queryFlow,
  formStoreQueryParamValues,
  trialType,
}) => {
  const pathname = usePathname();
  const query = useSearchParams();
  const router = useRouter();
  const { chainQueryParamsToUrl } = useQueryParams();
  const api = useAPI();

  const { setDiscountCode: setDiscountCodeInStore } = useDiscountStore();

  const {
    initialiseStore,
    setFlowFieldError,
    setFlowFieldValue,
    setFlowFieldsetData,
    getFlowErrors,
    setFlowCatData,
    getActiveFieldValue,
    setPrePopulatedData,
  } = useNewFormsServiceStore();
  const returnToPath = useReturnToPath();
  const { canUseQuickCheckout, purchaseInProgress, setPurchaseInProgress } =
    usePurchase();

  useEffect(() => {
    initialiseStore(flow);
    if (flow.mappedFlow.prepopulationData && flow.mappedFlow.prepopulationData.length) {
      setPrePopulatedData(flow.slug, flow.mappedFlow.prepopulationData);
    }
    if (trialType) {
      setFlowFieldValue(
        flow.slug,
        "flowTrialType",
        { submitValue: trialType as string },
        "0",
      );
    } else {
      setFlowFieldValue(
        flow.slug,
        "flowTrialType",
        { submitValue: undefined },
        "0",
      );
    }
  }, [ flow, initialiseStore, setFlowFieldValue, trialType ]);

  const sectionElement = useRef<Record<string, HTMLElement>>({});
  useEffect(() => {
    sectionElement.current[currentSection.slug]?.focus({ preventScroll: true });
    window.scroll({
      top: 0,
      left: 0,
    }); // This is to force a scroll to top after focus, without this the page opens to the focused element
  }, [ currentSection ]);

  useEffect(() => {
    setPurchaseInProgress(false);
  }, []);

  useEffect(() => {
    const setCat = async (catId: string | null) => {
      if (catId) {
        const cats = await api.Customer.getCats();
        const cat = cats.cats[catId];

        if (cat) {
          setFlowCatData(flow.slug, cat);
        }
      }
    };
    if (query.has("catId")) {
      void setCat(query.get("catId"));
    }
  }, [ query ]);

  useEffect(() => {
    if (formStoreQueryParamValues && formStoreQueryParamValues.length) {
      formStoreQueryParamValues.forEach((field) => {
        setFlowFieldValue(
          flow.slug,
          field.fieldName,
          { submitValue: field.fieldValue, displayValue: field.fieldValue },
          "0",
        );
        // Remove the query param so reloading doesn't affect changed values

        const newQueryParams: Record<string, string> = {};

        for (const [ key, value ] of query.entries()) {
          if (key !== field.fieldName) {
            newQueryParams[key] = value;
          }
        }

        void router.replace(chainQueryParamsToUrl(pathname, newQueryParams));
      });
    }
  }, [
    flow,
    formStoreQueryParamValues,
    initialiseStore,
    router,
    setFlowFieldValue,
    trialType,
  ]);

  useEffect(() => {
    if (query.has("discount_code")) {
      setDiscountCodeInStore(flow.slug, query.get("discount_code") as string);

      const newQueryParams: Record<string, string> = {};
      for (const [ key, value ] of query.entries()) {
        if (key !== "discount_code") {
          newQueryParams[key] = value;
        }
      }
      void router.replace(chainQueryParamsToUrl(pathname, newQueryParams));
    }
  }, [ query ]);

  const continueText: { text: string; alternative: boolean } =
    useNewFormsServiceStore(({ flows }) => {
      if (
        currentSection &&
        currentSection.conditional_continue_text &&
        currentSection.use_conditional_logic
      ) {
        const fieldValues = flows
          ?.find((f) => f.id === flow.slug)
          ?.fields?.filter(
            (fi) => fi.key.fieldName === currentSection.conditional_name,
          );
        const anyMatchesCondition = fieldValues?.some(
          (fv) => fv.data.submitValue?.toString().toLowerCase() ===
            currentSection.conditional_value?.toString().toLowerCase(),
        );
        const showAltText = currentSection.exclusionary
          ? !anyMatchesCondition
          : anyMatchesCondition;
        return showAltText
          ? {
            text: currentSection.conditional_continue_text,
            alternative: true,
          }
          : { text: "NEXT", alternative: false };
      }
      return {
        text: currentSection.conditional_continue_text || "NEXT",
        alternative: false,
      };
    });

  const getCatProfileIcon = useCallback((catLifeStage: CatLifeStage | undefined): IconSvg => {
    if (catLifeStage === "kitten") return "catProfileKitten";
    if (catLifeStage === "senior") return "catProfileSenior";
    return "catProfile";
  }, []);

  const spacerContent = useMemo(() => {
    const defaultSpacers = (
      <>
        <Spacer size="3xl" />
        <Spacer size="3xl" />
        <Spacer size="3xl" className="lg:hidden" />
      </>
    );

    // Handle default case
    if (!currentSection.number_of_spacers || currentSection.number_of_spacers === "default") {
      return defaultSpacers;
    }

    // Special case for single spacer
    if (currentSection.number_of_spacers === "1") {
      return <Spacer size="lg" />;
    }

    // For all other cases (2,3,4), create that many spacers
    const numSpacers = Number(currentSection.number_of_spacers);
    return (
      <>
        { Array.from({ length: numSpacers }).map((_, index) => (
          <Spacer key={index} size="3xl" />
        )) }
      </>
    );
  }, [ currentSection.number_of_spacers ]);

  return (
    <>
      { flow.mappedFlow.banner_copy && (
        <FlowBanner>{ flow.mappedFlow.banner_copy }</FlowBanner>
      ) }
      <Main
        className={
          flow.mappedFlow.fill_screen
            ? "min-h-[calc(100vh-50px)] xl:min-h-[calc(100vh-88px)]"
            : undefined
        }
      >
        { !flow.mappedFlow.hide_progress_bar ? (
          <Container>
            <ProgressSteps
              flow={ flow }
              currentForm={ currentForm.slug }
              currentSection={ currentSection.slug }
            />
          </Container>
        ) : (
          <Spacer size="xl" />
        ) }
        <div key={ currentForm.slug }>
          <div
            key={ currentSection.slug }
            className={
              "focus-visible:outline-none focus-visible:ring-transparent focus-visible:ring-offset-transparent"
            }
            ref={ (element) => {
              if (element) {
                sectionElement.current[currentSection.slug] = element;
              }
            } }
            tabIndex={ 0 }
          >
            { !!getFlowErrors(flow.slug)?.length && (
              <Container>
                <Grid>
                  <Column spans={ { lg: 6 } } offset={ { lg: 4 } }>
                    <AlertPrompt style="error" size="fill" hideImage>
                      <>
                        { getFlowErrors(flow.slug)?.map((error) => (
                          <Text
                            key={ error }
                            size={ legacySizeCollectionMap.bodySm }
                          >
                            { error }
                          </Text>
                        )) }
                      </>
                    </AlertPrompt>
                  </Column>
                </Grid>
                <Spacer size="lg" />
              </Container>
            ) }
            <Text
              tag="h2"
              size={
                currentSection.title_display === "default"
                  ? legacySizeCollectionMap.titleMd
                  : legacySizeCollectionMap.titleLg
              }
              display={
                currentSection.title_display === "default"
                  ? "subtitle"
                  : "title"
              }
              align="center"
              className="first-letter:capitalize px-4"
            >
              { currentSection.title &&
                interpolateString(
                  currentSection.title,
                  getFlowFieldValuesForInterpolation(
                    queryFlow || flow.slug,
                    "0",
                    "GET_ALL_WITH_REMOVED",
                  ),
                  Interpolator.FRONTEND,
                ) }
            </Text>
            <SectionComponents
              flowId={ queryFlow || flow.slug }
              components={ currentSection.component_header }
            />
            <Section
              size={
                { top: currentSection.header_margin === "none" ? "none" : "lg",
                  bottom: currentSection.footer_margin === "none" ? "none" : "lg" }
              }
              overflow={ currentSection.overflow_display || "default" }
            >
              <>
                { currentSection.fieldsets?.map((fieldset, fieldsetIndex) => (
                  <Fragment key={ fieldset._uid }>
                    { isFieldset(fieldset) &&
                  getRepeatedFieldsArray(
                    flow.slug,
                    fieldset.repeat_from,
                  ).some((linkingId) => showFieldset(fieldset, linkingId, flow.slug)) && (
                      <>
                        { fieldset.title &&
                          fieldset.title_position === "default" && (
                          <Container>
                            <Text
                              tag={ currentSection ? "h3" : "h2" }
                              size={ legacySizeCollectionMap.titleMd }
                              display="subtitle"
                              align={ fieldset.title_align || "center" }
                              parseChildren
                              className="first-letter:capitalize"
                            >
                              { interpolateString(
                                fieldset.title,
                                getFlowFieldValuesForInterpolation(
                                  queryFlow || flow.slug,
                                  "0",
                                  "GET_ALL_WITH_REMOVED",
                                ),
                                Interpolator.FRONTEND,
                              ) }
                            </Text>
                            <Spacer size="lg" />
                            { fieldset.subtitle ? (
                              <>
                                <Text
                                  tag={ currentSection ? "h3" : "h2" }
                                  display={fieldset.subtitleDisplay || "subtitle"}
                                  align={ fieldset.title_align || "center" }
                                  parseChildren
                                  className="first-letter:capitalize"
                                >
                                  { interpolateString(
                                    fieldset.subtitle,
                                    getFlowFieldValuesForInterpolation(
                                      queryFlow || flow.slug,
                                      "0",
                                      "GET_ALL_WITH_REMOVED",
                                    ),
                                    Interpolator.FRONTEND,
                                  ) }
                                </Text>
                                <Spacer size="md"/>
                              </>
                            ) : null
                            }
                          </Container>
                        ) }
                        { getRepeatedFieldsArray(
                          flow.slug,
                          fieldset.repeat_from,
                        ).filter((linkingId) => showFieldset(fieldset, linkingId, flow.slug))
                          .map((linkingId, idx) => (
                            <div
                              key={ `${fieldset._uid}-${linkingId}` }
                              className={ clsx(
                                "relative",
                                `${fieldset.fields
                                  .map((field) => field.component)
                                  .some((component) => [
                                    "payment_details",
                                    "quick_checkout",
                                  ].includes(component))
                                  ? ""
                                  : `z-[${20 - fieldsetIndex - idx}]`
                                }` /* z-[20], z-[19] z-[18], z-[17], z-[16], z-[15], z-[14], z-[13], z-[12], z-[11], z-[10], z-[9], z-[8], z-[7], z-[6], z-[5], z-[4], z-[3], z-[2], z-[1], z-[0])} */,
                                `${fieldset.type === "quickCheckout" &&
                                  !canUseQuickCheckout
                                  ? "hidden"
                                  : ""
                                }`,
                              ) }
                            >
                              <Fieldset
                                id={ fieldset.collapsable_id || fieldset.element_id }
                                width={ fieldset.width }
                                theme={ fieldset.theme }
                                className={ clsx(
                                  [ undefined, "default" ].includes(
                                    fieldset.margin,
                                  ) && "mb-4 lg:mb-8 flex",
                                  ![ undefined, "no-repeat" ].includes(
                                    fieldset.repeat_from,
                                  ) &&
                                  ![ undefined, "default" ].includes(
                                    fieldset.theme,
                                  ) &&
                                  (idx % 2 === 0 ? "-rotate-1" : "rotate-1"),
                                  ![ undefined, "default" ].includes(
                                    fieldset.theme,
                                  ) && "p-4",
                                ) }
                                index={ idx }
                              >
                                <div>
                                  { fieldset.title &&
                                    fieldset.title_position === "inside" && (
                                    <>
                                      <div className="flex items-center justify-between">
                                        <Text
                                          tag={ currentSection ? "h3" : "h2" }
                                          size={ legacySizeCollectionMap.titleMd }
                                          display="subtitle"
                                          align={ fieldset.title_align || "center" }
                                          parseChildren
                                          className="first-letter:capitalize"
                                        >
                                          { interpolateString(
                                            fieldset.title,
                                            getFlowFieldValuesForInterpolation(
                                              flow.slug,
                                              linkingId,
                                              "GET_RELATED",
                                            ),
                                            Interpolator.FRONTEND,
                                          ) }
                                        </Text>
                                        { fieldset.title_image && (
                                          <div className="max-w-[115px]">
                                            <Image
                                              image={ getStoryBlokImage(
                                                fieldset.title_image,
                                              ) }
                                              alt={ fieldset.title_image.alt }
                                            />
                                          </div>
                                        ) }
                                        { fieldset.collapsable_type === "closed" &&
                                            fieldset.collapsable_edit_link &&
                                            !fieldset.collapsed_hide_edit && (
                                          <Text
                                            display="title"
                                            className="underline"
                                            size={ { default: "3xs", md: "2xs" } }
                                          >
                                            <Link
                                              href={ chainQueryParamsToUrl(
                                                getStoryBlokLink(
                                                  fieldset.collapsable_edit_link,
                                                ),
                                              ) }
                                            >
                                                  Change
                                            </Link>
                                          </Text>
                                        ) }
                                        { fieldset.collapsable_id &&
                                            fieldset.collapsable_type ===
                                            "togglable" &&
                                            getFieldsetState(
                                              flow.slug,
                                              fieldset.collapsable_id,
                                              linkingId,
                                            )?.data.state === "closed" &&
                                            !fieldset.collapsed_hide_edit && (
                                          <button
                                            onClick={ () => setFlowFieldsetData(
                                              flow.slug,
                                              fieldset.collapsable_id as string,
                                              { state: "open" },
                                              "0",
                                            )
                                            }
                                          >
                                            <Text
                                              display="title"
                                              className="underline"
                                              size={ {
                                                default: "3xs",
                                                md: "2xs",
                                              } }
                                            >
                                                  Change
                                            </Text>
                                          </button>
                                        ) }
                                      </div>
                                      { fieldset.collapsed_description &&
                                          fieldset.collapsable_id &&
                                          getFieldsetState(
                                            flow.slug,
                                            fieldset.collapsable_id,
                                            linkingId,
                                          )?.data.state === "closed" && (
                                        <>
                                          <Spacer size="md" />
                                          <Text
                                            size={
                                              legacySizeCollectionMap.bodySm
                                            }
                                          >
                                            { fieldset.collapsed_description }
                                          </Text>
                                        </>
                                      ) }
                                      <Spacer size="lg" />
                                    </>
                                  ) }
                                  { fieldset.display?.name ===
                                    "withHorizontalRule" &&
                                    fieldset.label &&
                                    getRepeatedFieldsArray(
                                      flow.slug,
                                      fieldset.repeat_from,
                                    ).length > 1 && (
                                    <>
                                      <CatDivider
                                        catName={ interpolateString(
                                          fieldset.label,
                                          getFlowFieldValuesForInterpolation(
                                            flow.slug,
                                            linkingId,
                                            "GET_RELATED",
                                          ),
                                          Interpolator.FRONTEND,
                                        ) }
                                      />
                                      <Spacer size="md" />
                                    </>
                                  ) }
                                  <div className="flex">
                                    { [ "withIcon", "withImage" ].includes(
                                      fieldset.display?.name ?? "",
                                    ) && (
                                      <div className="w-[70px] h-[60px] mr-4">
                                        { fieldset.display?.name === "withIcon" && (
                                          <Icon icon="cross" />
                                        ) }
                                        { fieldset.display?.name === "withImage" &&
                                            fieldset.display.image && (
                                          <Icon icon={ getCatProfileIcon(getActiveFieldValue(flow.slug, "catLifeStage", linkingId)?.data.submitValue as undefined | CatLifeStage) } size="fit" />
                                        ) }
                                        { fieldset.label && (
                                          <Text
                                            size={ { default: "3xs", md: "2xs" } }
                                            display="title"
                                            align="center"
                                          >
                                            { interpolateString(
                                              fieldset.label,
                                              getFlowFieldValuesForInterpolation(
                                                flow.slug,
                                                linkingId,
                                                "GET_RELATED",
                                              ),
                                              Interpolator.FRONTEND,
                                            ) }
                                          </Text>
                                        ) }
                                      </div>
                                    ) }
                                    <div className="w-full">
                                      <div
                                        className={ `w-full ${!fieldset.collapsable_type ||
                                          !fieldset.collapsable_id ||
                                          getFieldsetState(
                                            flow.slug,
                                            fieldset.collapsable_id,
                                            linkingId,
                                          )?.data.state === "open"
                                          ? ""
                                          : "hidden"
                                        }` }
                                      >
                                        { fieldset.description && (
                                          <Information
                                            description={ interpolateString(
                                              fieldset.description,
                                              getFlowFieldValuesForInterpolation(
                                                flow.slug,
                                                linkingId,
                                              ),
                                              Interpolator.FRONTEND,
                                            ) }
                                            style="icon"
                                          />
                                        ) }
                                        { fieldset.body && (
                                          <>
                                            <TextBody tag="div" align="center" size={ 2 }>
                                              { renderStoryBlokRichText(fieldset.body, { unwrapResolvers: true }) }
                                            </TextBody>
                                            <Spacer size="lg" />
                                          </>
                                        ) }
                                        <Grid>
                                          { fieldset.fields?.map((field) => (
                                            <Fragment
                                              key={ `${field._uid}-${linkingId}` }
                                            >
                                              { isComponentSet(field) ? (
                                                <Column
                                                  spans={
                                                    field?.column_size
                                                      ? fieldSpanObjectMap[field.column_size as ColumnSize]
                                                      : 12
                                                  }
                                                  className={ `${(field?.column_size === "breakout"
                                                  ) && "transform -translate-x-[70px] w-[calc(100%+70px)]"
                                                  }` }
                                                >
                                                  <SectionComponents
                                                    canContinue={ canContinue }
                                                    flowId={ flow.slug }
                                                    linkingId={ linkingId }
                                                    useConditionalLogic={ field.use_conditional_logic }
                                                    conditions={ [
                                                      {
                                                        conditional_name: field.conditional_name,
                                                        conditional_value: field.conditional_value,
                                                        exclusionary: field.exclusionary,
                                                      },
                                                      ...(field?.multiple_conditions ?? []),
                                                    ] }
                                                    { ...field }
                                                  />
                                                </Column>
                                              ) : (
                                                <>
                                                  { isNonFillableField(field) ? (
                                                    <NonFillableField
                                                      field={ field }
                                                      linkingId={ linkingId }
                                                      fieldsetState={
                                                        fieldset.collapsable_id
                                                          ? getFieldsetState(
                                                            flow.slug,
                                                            fieldset.collapsable_id,
                                                            linkingId,
                                                          )?.data.state
                                                          : undefined
                                                      }
                                                      next={ next }
                                                      canContinue={ canContinue }
                                                      flow={ flow }
                                                    />
                                                  ) : (
                                                    <>
                                                      <Field
                                                        component={
                                                          field.component as FormComponentName
                                                        }
                                                        field={
                                                          field as FillableFieldComponent
                                                        }
                                                        linkingId={ linkingId }
                                                        flowSlug={
                                                          queryFlow || flow.slug
                                                        }
                                                        fieldsetDisplay={
                                                          fieldset.display?.name
                                                        }
                                                        showField={ showField(
                                                          field as FillableFieldComponent,
                                                          linkingId,
                                                          flow.slug,
                                                        ) }
                                                        captureFieldValue={
                                                          "options" in field
                                                        } // We'll only capture options-based fields as they are the only ones with pre-determined values
                                                      />
                                                    </>
                                                  ) }
                                                </>
                                              ) }
                                            </Fragment>
                                          )) }
                                        </Grid>
                                      </div>

                                      <div
                                        className={ `${fieldset.collapsable_type &&
                                          fieldset.collapsable_id &&
                                          fieldset.collapsable_id &&
                                          getFieldsetState(
                                            flow.slug,
                                            fieldset.collapsable_id,
                                            linkingId,
                                          )?.data.state !== "open"
                                          ? ""
                                          : "hidden"
                                        }` }
                                      >
                                        { fieldset.collapsed_preview?.map((blok) => (
                                          <>
                                            <Text
                                              tag="div"
                                              size={ legacySizeCollectionMap.bodySm }
                                            >
                                              { blok.block_items.map((blockItem) => (
                                                <>
                                                  { /*
                                            specialist fields - TODO: If test wins mve it out of slug and clean up
                                            */ }
                                                  { blockItem.line_items
                                                    .filter(
                                                      (itemFieldName) => itemFieldName === "address",
                                                    )
                                                    .map((name, index) => (
                                                      <div
                                                        className="flex space-x-8"
                                                        key={ `address-${index}` }
                                                      >
                                                        <div>
                                                          { JSON.stringify(
                                                            (
                                                              getFieldValue(
                                                                flow.slug,
                                                                "address",
                                                                linkingId,
                                                              )?.data
                                                                ?.displayValue as AddressValue
                                                            )?.billingAddress,
                                                          ) !==
                                                            JSON.stringify(
                                                              (
                                                                getFieldValue(
                                                                  flow.slug,
                                                                  "address",
                                                                  linkingId,
                                                                )?.data
                                                                  ?.displayValue as AddressValue
                                                              )?.shippingAddress,
                                                            ) && (
                                                            <>
                                                              <Text
                                                                size={
                                                                  legacySizeCollectionMap.titleXs
                                                                }
                                                                display="title"
                                                              >
                                                                  Delivery address:
                                                              </Text>
                                                              <Spacer size="sm" />
                                                            </>
                                                          ) }
                                                          <address className="not-italic">
                                                            { Object.entries(
                                                              (
                                                                getFieldValue(
                                                                  flow.slug,
                                                                  "address",
                                                                  linkingId,
                                                                )?.data
                                                                  ?.displayValue as AddressValue
                                                              )?.shippingAddress ||
                                                              {},
                                                            )
                                                              .filter(([ key ]) => [
                                                                "line1",
                                                                "line2",
                                                                "postcode",
                                                                "city",
                                                                "phone",
                                                              ].includes(key))
                                                              .filter(
                                                                ([ , addressLine ]) => addressLine.trim()
                                                                  .length,
                                                              )
                                                              .map(
                                                                ([
                                                                  ,
                                                                  addressLine,
                                                                ]) => (
                                                                  <>
                                                                    { addressLine }
                                                                    <br />
                                                                  </>
                                                                ),
                                                              ) }
                                                          </address>
                                                        </div>
                                                        { JSON.stringify(
                                                          (
                                                            getFieldValue(
                                                              flow.slug,
                                                              "address",
                                                              linkingId,
                                                            )?.data
                                                              ?.displayValue as AddressValue
                                                          )?.billingAddress,
                                                        ) !==
                                                          JSON.stringify(
                                                            (
                                                              getFieldValue(
                                                                flow.slug,
                                                                "address",
                                                                linkingId,
                                                              )?.data
                                                                ?.displayValue as AddressValue
                                                            )?.shippingAddress,
                                                          ) && (
                                                          <div>
                                                            <Text
                                                              size={
                                                                legacySizeCollectionMap.titleXs
                                                              }
                                                              display="title"
                                                            >
                                                                Billing address:
                                                            </Text>
                                                            <Spacer size="sm" />
                                                            <address className="not-italic">
                                                              { Object.entries(
                                                                (
                                                                  getFieldValue(
                                                                    flow.slug,
                                                                    "address",
                                                                    linkingId,
                                                                  )?.data
                                                                    ?.displayValue as AddressValue
                                                                )?.billingAddress ||
                                                                  {},
                                                              )
                                                                .filter(([ key ]) => [
                                                                  "line1",
                                                                  "line2",
                                                                  "postcode",
                                                                  "city",
                                                                  "phone",
                                                                ].includes(key))
                                                                .filter(
                                                                  ([
                                                                    ,
                                                                    addressLine,
                                                                  ]) => addressLine.trim()
                                                                    .length,
                                                                )
                                                                .map(
                                                                  ([
                                                                    ,
                                                                    addressLine,
                                                                  ]) => (
                                                                    <>
                                                                      { addressLine }
                                                                      <br />
                                                                    </>
                                                                  ),
                                                                ) }
                                                            </address>
                                                          </div>
                                                        ) }
                                                      </div>
                                                    )) }
                                                  { /* standard fields */ }
                                                  <p className="!mb-0">
                                                    { blockItem.line_items
                                                      .filter(
                                                        (itemFieldName) => ![ "address" ].includes(
                                                          itemFieldName,
                                                        ),
                                                      )
                                                      .map(
                                                        (itemFieldName) => getFieldValue(
                                                          flow.slug,
                                                          itemFieldName,
                                                          linkingId,
                                                        )?.data?.displayValue?.toString() ??
                                                          "",
                                                      )
                                                      .join(" ") }
                                                    <br />
                                                  </p>
                                                </>
                                              )) }
                                            </Text>
                                          </>
                                        )) }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                { (!fieldset.collapsable_type ||
                                  !fieldset.collapsable_id ||
                                  getFieldsetState(
                                    flow.slug,
                                    fieldset.collapsable_id,
                                    linkingId,
                                  )?.data.state === "open") && (
                                  <FieldsetFooter
                                    components={ fieldset.footer_components }
                                    flowId={ flow.slug }
                                    linkingId={ linkingId }
                                  />
                                ) }
                              </Fieldset>
                            </div>
                          )) }
                      </>
                    ) }
                    { isComponentSet(fieldset) && (
                      <SectionComponents
                        canContinue={ canContinue }
                        flowId={ flow.slug }
                        useConditionalLogic={ fieldset.use_conditional_logic }
                        conditions={ [
                          {
                            conditional_name: fieldset.conditional_name,
                            conditional_value: fieldset.conditional_value,
                            exclusionary: fieldset.exclusionary,
                          },
                          ...(fieldset?.multiple_conditions ?? []),
                        ] }
                        { ...fieldset }
                      />
                    ) }
                  </Fragment>
                )) }
                { spacerContent }
              </>
            </Section>
            <SectionComponents
              flowId={ flow.slug }
              components={ currentSection.component_footer }
            />
          </div>
        </div >
        {
          flow.mappedFlow.navigation_display === "static" && (
            <Container>
              <Grid>
                <Column
                  spans={ { lg: currentSection.hide_back_button ? 12 : 6 } }
                  align={ {
                    lg: currentSection.hide_back_button ? "center" : "start",
                  } }
                  className={ `lg:order-last ${currentSection.continue_action_handled_within_section &&
                    "hidden"
                  }` }
                >
                  <FlowContinue
                    data-testid="flowContinueButton"
                    onError={ (error) => error &&
                      setFlowFieldError(flow.slug, error?.name ?? "", error, "0")
                    }
                    onClick={ next }
                    formContinueWithAction={
                      currentSection.continue_with_action?.[0]
                    }
                    isLastSectionInFlow={
                      currentSectionIndex === sections.length - 1
                    }
                    field={
                      flow.mappedFlow.flow_exit?.[0] ?? ({} as ActionCTAField)
                    }
                    flowId={ flow.slug }
                    isDisabled={ !canContinue }
                    continueText={ continueText.text }
                    alternative={ continueText.alternative }
                  />
                </Column>
                <Column
                  align={ {
                    lg: currentSection.continue_action_handled_within_section
                      ? "center"
                      : "end",
                  } }
                  spans={ {
                    lg: currentSection.continue_action_handled_within_section
                      ? 12
                      : 6,
                  } }
                  className={ currentSection.hide_back_button ? "hidden" : "" }
                >
                  { !currentSection.hide_back_button && (
                    <FlowBack
                      onClick={ back }
                      isDisabled={ currentSectionIndex === 0 && !returnToPath }
                    />
                  ) }
                </Column>
              </Grid>
              <Spacer size="2xl" />
            </Container>
          )
        }
      </Main >
      {
        flow.mappedFlow.navigation_display === "sticky" &&
        !currentSection.continue_action_handled_within_section && (
          <div
            className={ `sticky bottom-0 left-0 w-full p-4 z-30 ${themeRootClassMap.brand}` }
          >
            <Container>
              <Grid>
                <Column
                  spans={ 12 }
                  align="center"
                  className={
                    currentSection.continue_action_handled_within_section
                      ? "hidden"
                      : undefined
                  }
                >
                  <FlowContinue
                    data-testid="flowContinueButton"
                    onError={ (error) => error &&
                      setFlowFieldError(flow.slug, error.name ?? "", error, "0")
                    }
                    onClick={ next }
                    isLastSectionInFlow={
                      currentSectionIndex === sections.length - 1
                    }
                    formContinueWithAction={
                      currentSection.continue_with_action?.[0]
                    }
                    field={
                      flow.mappedFlow.flow_exit?.[0] ?? ({} as ActionCTAField)
                    }
                    flowId={ flow.slug }
                    isDisabled={ !canContinue }
                    continueText={ continueText.text }
                    alternative={ continueText.alternative }
                  />
                </Column>
              </Grid>
            </Container>
          </div>
        )
      }
      < LoadingPopover
        show={ purchaseInProgress }
        message="We're processing your order"
      />
    </>
  );
};

export default FormsContent;
