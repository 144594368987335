import { BrandEnum, BrandWithoutExcluded } from "./brands";

export interface CompetitorData {
  name: string;
  pricePerDay: string;
  productName: string;
  ingredients: string;
}

export const COMPETITOR_DATA: Record<BrandWithoutExcluded, CompetitorData> = {
  [BrandEnum.FELIX]: {
    name: "Felix",
    pricePerDay: "1.77",
    productName: "Chicken in Jelly",
    ingredients:
      "Meat and animal derivatives (Chicken/Beef 4%); Vegetable protein extracts; Fish and fish derivatives (Salmon/Tuna 4%); Minerals; Various sugars",
  },
  [BrandEnum.WHISKAS]: {
    name: "Whiskas",
    pricePerDay: "1.59",
    productName: "Poultry Feasts",
    ingredients:
      "Meat and animal by-products (35%, including 4% chicken chunks*), grain, minerals, vegetable by-products, sugars.",
  },
  [BrandEnum.GOURMET]: {
    name: "Gourmet",
    pricePerDay: "1.57",
    productName: "Wet Adult Food",
    ingredients:
      "Meat and animal derivatives (23% of which beef 4%), Vegetable protein extracts, Fish and fish derivatives, Vegetables (1.2% dehydrated tomatoes, equivalent to 10.8% tomatoes), Derivatives of vegetable origin, Various sugars, minerals.",
  },
  [BrandEnum.SHEBA]: {
    name: "Sheba",
    pricePerDay: "2.12",
    productName: "Natures Collection",
    ingredients:
      "Meat and Animal Derivatives (45%, Natural* 96%; including Chicken 4%), Vegetables* (including Dried Red Peppers, Carrots and Peas Mix 1.5%, equivalent to Red Peppers, Carrots and Peas Mix 4%), Derivatives of Vegetable Origin*, Minerals, Yeast*, Various Sugars",
  },
  [BrandEnum.ROYAL_CANIN]: {
    name: "Royal Canin",
    pricePerDay: "4.98",
    productName: "Feline Instinctive",
    ingredients:
      "Meat and animal derivatives, fish and fish derivatives, vegetable protein extracts, cereals, minerals, derivatives of vegetable origin, yeasts.",
  },
  [BrandEnum.PURINA_ONE]: {
    name: "Purina One",
    pricePerDay: "1.31",
    productName: "Mini Fillets",
    ingredients:
      "Meat and animal derivatives (of which Chicken 4%), vegetable protein extracts, derivatives of vegetable origin, oils and fats, fish and fish derivatives, minerals, various sugars, vegetables (0.6% dehydrated Green Beans, equivalent to 5.4% Green Beans).",
  },
  [BrandEnum.APPLAWS]: {
    name: "Applaws",
    pricePerDay: "1.87",
    productName: "Chicken in Broth",
    ingredients: "Chicken Breast: Chicken Breast 75%, Chicken Broth, Rice.",
  },
  [BrandEnum.ALMO_NATURE]: {
    name: "Almo Nature",
    pricePerDay: "2.85",
    productName: "Light Meal Chicken",
    ingredients: "Chicken breast (55%), chicken stock (43%), rice (2%).",
  },
  [BrandEnum.AVA]: {
    name: "AVA",
    pricePerDay: "1.25",
    productName: "Chicken In Gravy",
    ingredients:
      "Chicken (30%), Tuna, Sunflower Oil, Pea Protein, Yeast Extract, Cellulose Fibre (1.3%), Minerals (1%), Fish Oil (1%), Tapioca Starch, Yeast (as a source of Prebiotic Mannan-oligosaccharides & Beta-glucans) (0.045%), Chicory Root Extract (as a source of Prebiotic Fructo-oligosaccharides) (0.03%), Yucca Extract (0.005%), Glucosamine (0.005%), Dried Blueberry (0.003%), Cranberry Extract (0.003%), Pomegranate Extract (0.003%), Chondroitin (0.0017%).",
  },
  [BrandEnum.BLINK]: {
    name: "Blink!",
    pricePerDay: "2.99",
    productName: "Wet Food in Jelly",
    ingredients:
      "Chicken and Duck Fillets: British Chicken Fillets 52%, Tender British Duck 8%, Chicken Broth 39%, Vitamins and Minerals 1%.",
  },
  [BrandEnum.BURNS]: {
    name: "Burns",
    pricePerDay: "0.53",
    productName: "Duck & Potato",
    ingredients:
      "Duck Meal (39%), Potato (34%), Buckwheat (15%), Duck Fat, Chicken Liver, Salmon Oil, Minerals",
  },
  [BrandEnum.CARNILOVE]: {
    name: "Carnilove",
    pricePerDay: "6.47",
    productName: "Chicken & Lamb",
    ingredients:
      "35% chicken meat, chicken liver, chicken hearts, 20% lamb, 2% pea flour, 1% cranberries,1% lignocellulose, 0.1% linseed oil.",
  },
  [BrandEnum.CRAVE]: {
    name: "Crave",
    pricePerDay: "0.33",
    productName: "Turkey & Chicken",
    ingredients:
      "Natural Chicken (31.1%): Fresh Chicken (17.5%), Dried Chicken Protein, Dried Whole Peas, Turkey (17.5%): Dried Poultry Protein, Turkey Liver Digest, Beef Greaves Protein, Pea Starch, Poultry Fat, Dried Beet Pulp, Dried Poultry Protein, Dried Alfalfa, Minerals, Sunflower Oil.",
  },
  [BrandEnum.EDGARD_COOPER]: {
    name: "Edgard Cooper",
    pricePerDay: "3.87",
    productName: "Chunks in Sauce",
    ingredients:
      "Chicken 46%, beef 7%, minerals, sage (dried) 0.02%, turmeric (dried) 0.02%, cranberries (dried) 0.01%.",
  },
  [BrandEnum.ENCORE]: {
    name: "Encore",
    pricePerDay: "2.32",
    productName: "Chicken in Broth",
    ingredients: "Chicken Breast 75%, Chicken Broth, Rice.",
  },
  [BrandEnum.FERINGA]: {
    name: "Feringa",
    pricePerDay: "2.80",
    productName: "Chicken & Veal",
    ingredients:
      "45% chicken (heart, meat, liver, gizzards, neck), 25.9% stock, 24% veal (heart), 1% carrots, 1% minerals, 0.1% dandelion.",
  },
  [BrandEnum.FRESHPET]: {
    name: "Freshpet",
    pricePerDay: "1.75",
    productName: "Grilled Chicken",
    ingredients:
      "Chicken (75%), potato starch, pea protein, digest, minerals, rice flour, vinegar, psyllium fibre, celery powder, yeasts.",
  },
  [BrandEnum.GO_CAT]: {
    name: "GoCat",
    pricePerDay: "0.25",
    productName: "Tuna and Herring",
    ingredients:
      "Cereals, Meat and animal derivatives, Vegetable protein extracts, Oils and fats, Derivatives of vegetable origin, Fish and fish derivatives (3.2%*), Minerals, Vegetables (0.6%), Yeasts.",
  },
  [BrandEnum.HARRINGTONS]: {
    name: "Harringtons",
    pricePerDay: "1.38",
    productName: "Meaty Selection",
    ingredients:
      "32% Freshly Prepared Chicken, Animal Protein, Tapioca, Vitamins & Minerals, Natural Caramel Syrup.",
  },
  [BrandEnum.HILLS]: {
    name: "Hills",
    pricePerDay: "4.40",
    productName: "Wet Food Chicken",
    ingredients:
      "Meat and animal derivatives (chicken 31%), derivatives of vegetable origin, cereals, various sugars, vegetable protein extracts, minerals, eggs and egg derivatives, oils and fats.",
  },
  [BrandEnum.IAMS]: {
    name: "IAMS",
    pricePerDay: "1.03",
    productName: "Delights Wet Food",
    ingredients:
      "Meat and animal derivatives (42% amongst which 6% turkey and 4% duck), derivatives of vegetable origin, minerals.",
  },
  [BrandEnum.JAMES_WELLBELOVED]: {
    name: "James Wellbeloved",
    pricePerDay: "2.46",
    productName: "Turkey Gravy",
    ingredients:
      "Turkey (40.7%), pea protein, tapioca starch, tomato pomance, minerals, sunflower oil, linseed oil, tomato powder, fish oil, chicory extract, cranberry extract (0.013%), yucca extract (0.005%).",
  },
  [BrandEnum.LILYS_KITCHEN]: {
    name: "Lily's Kitchen",
    pricePerDay: "3.63",
    productName: "Tasty Cuts in Gravy",
    ingredients: "53% Freshly Prepared: Chicken 40%, Pork 13%, Gravy, Minerals.",
  },
  [BrandEnum.MEOWING_HEADS]: {
    name: "Meowing Heads",
    pricePerDay: "2.69",
    productName: "Paw Lickin Chicken",
    ingredients:
      "Fresh Deboned Chicken (43%), Chicken Broth (25%), Fresh Deboned BeefV (25%), Sunflower oil, Salmon oil, Minerals",
  },
  [BrandEnum
    .NATURES_MENU]: {
    name: "Natures Menu",
    pricePerDay: "2.59",
    productName: "Chicken & Turkey",
    ingredients:
      "55% chicken, 15% turkey and 30% broth with added vitamins and minerals.",
  },

  [BrandEnum.ORIJEN]: {
    name: "Orijen",
    pricePerDay: "0.52",
    productName: "Original Cat",
    ingredients:
      "Fresh chicken (20%), raw turkey (10%), raw whole herring (9%), fresh chicken giblets (liver, heart) (6%), raw whole hake (5%), raw turkey liver (5%), fresh eggs (5%), dehydrated chicken (4%), dehydrated turkey (4%), dehydrated mackerel (4%), dehydrated sardine (4%), dehydrated herring (4%).",
  },
  [BrandEnum.PURR_MIAOW]: {
    name: "Purr&Miaow",
    pricePerDay: "0.47",
    productName: "Duck & Turkey",
    ingredients:
"Dried salmon 26%, dried herring 21%, yellow peas, fresh salmon 12%, chicken fat 10%, pea protein, brewer´s yeast, dried apple pulp 3%, hydrolyzed chicken liver 3%, salmon oil 3%, linseed, glucosamine, chondroitin sulphate, prebiotics MOS, prebiotics FOS, psyllium husk and seeds 0.015%, yucca mojave 0.01%, dried cranberry 0.0012%, dried raspberry 0.0012%, dried blueberry 0.0011%, dried rosemary 0.0005%, dried thyme 0.0005%, dried algae 0.0005%.",
  },
  [BrandEnum.REPUBLIC_OF_CATS]: {
    name: "Republic of Cats",
    pricePerDay: "1.00",
    productName: "Shredded Chicken",
    ingredients: "Meat and animal derivatives 27% (at least 4% is chicken), vegetable protein extracts, fish and fish derivatives, derivatives of vegetable origin, various sugars, minerals.",
  },
  [BrandEnum.SCRUMBLES]: {
    name: "Scrumbles",
    pricePerDay: "6.63",
    productName: "Chicken in Broth",
    ingredients: "Chicken Breast 60%, Chicken Broth, Slippery Elm 0.05%.",
  },
  [BrandEnum.SUPERMARKET_BRAND]: {
    name: "Supermarket brand (e.g. Tesco)",
    pricePerDay: "0.38",
    productName: "Poultry Selection",
    ingredients: "Meat and Animal Derivatives (38%, including 4% Chicken), Fish and Fish Derivatives, Minerals, Derivatives of Vegetable Origin, Various Sugars.",
  },
  [BrandEnum.THRIVE]: {
    name: "Thrive",
    pricePerDay: "2.33",
    productName: "Wet Food Chicken",
    ingredients: "Chicken breast (75%), Chicken stock, Sunflower Oil, Vitamins and Minerals.",
  },
  [BrandEnum
    .TOMOJO]: {
    name: "Tomojo",
    pricePerDay: "0.46",
    productName: "Grain Free Kibble",
    ingredients: "Dehydrated insects (33% min.), potato starch, peas*, pea proteins, poultry fat, sunflower oil, yeasts, animal protein hydrolysate, beet pulp, dicalcium phosphate, lentils, calcium carbonate, whole chia seeds, flax, thyme, oregano, parsley, marjoram, spirulina. *from organic farming, origin France.",
  },
  [BrandEnum.UNTAMED]: {
    name: "Untamed",
    pricePerDay: "1.90",
    productName: "Chicken with Ham",
    ingredients: "Chicken breast 58%, Chicken broth 33%, Ham 5%, Sunflower oil, Tapioca 1%, Vitamins & Minerals.",
  },
};
