import { FC } from "react";

import Image from "apps/website/components/base/Image/Image";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { getFlowFieldValuesForInterpolation } from "@./state";
import Column from "apps/website/components/layout/Column/Column";
import TaggedSummary from "apps/website/components/feature/TaggedSummary/TaggedSummary";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";

import { getTagGroups } from "./getTagGroups";
import LampSVG from "./LampSVG";
import AngleArrowSVG from "./AngleArrowSVG";

interface ICatSummaryPropsC {
  flowSlug: string;
  linkingId: string;
  values: Record<string, string>;
}

export const CatSummaryC: FC<ICatSummaryPropsC> = ({ values, flowSlug, linkingId }) => {
  const groups = getTagGroups(values);

  return (
    <>
      { !!groups.length && (
        <>
          <Column className="bg-light-grey" component="CatSummaryC" data-linkingid={linkingId}>
            <>
              <div className="flex items-center space-x-2 mb-2">
                <div className={"items-center justify-center relative aspect-[3/4] w-16 flex-none"}>
                  <Image image={{
                    src: "https://a.storyblok.com/f/176726/290x381/f3b5cf231b/dom.png/m/320x0",
                    width: 290,
                    height: 381,
                  }} alt="In house vet Dom" className={themeRootClassMap.green}/>
                  <Image image={{
                    src: "/images/features/team/frame.png",
                    height: 232,
                    width: 183,
                  }} alt=""
                  className="absolute top-0 left-0"
                  />
                </div>
                <div className="flex items-center justify-center mr-4">
                  <TextSubtitle size={3}>
                    { "Dom, our in-house cat nutrition expert, recommends the following for " }
                    { interpolateString("[[$catName]]", getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND) }
                    { ":" }
                  </TextSubtitle>
                </div>
              </div>
              <TaggedSummary
                className="bg-white pb-0"
                groups={groups}
                wrap={false}
              />
              <div className="bg-white px-4 pb-4 pt-3">
                <div className="bg-[#ECF7FC] p-1 md:p-2 flex relative">
                  <LampSVG className="w-6 h-6 mr-2" />
                  <TextBody size={3} className="flex-1">
                    <span>
                      { interpolateString(
                        "P.S. Every packet of food is pre-measured out to meet [[$catPronounHisHerTheir]] daily calorie requirements.",
                        values,
                        Interpolator.FRONTEND,
                      ) }
                    </span>
                    <strong className='font-bold'>
                      { " No need to feed anything else!" }
                    </strong>
                  </TextBody>
                  <AngleArrowSVG className="absolute bottom-0 translate-y-3/4 right-8 z-10 " />
                </div>
              </div>
            </>
          </Column>
        </>
      ) }
    </>
  );
};
