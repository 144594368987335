import Image from "apps/website/components/base/Image/Image";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";

type FreshProductCarouselCardProps = {
  productName: string;
  productDescription: string | React.ReactNode;
  productImageSrc: string;
  productImageAlt: string;
};

const FreshProductCarouselCard: React.FC<FreshProductCarouselCardProps> = ({
  productName,
  productDescription,
  productImageSrc,
  productImageAlt,
}) => (
  <div className="bg-white p-2 min-w-[168px] max-w-[168px]">
    <div className="bg-[#E8F0F6] relative">
      <Image alt={ productImageAlt } image={{ src: productImageSrc, width: 168, height: 160 }} />
      <TextSubtitle size={2} className="absolute py-1 px-1.5 bg-black bottom-0 translate-y-1/2 uppercase text-white">{ productName }</TextSubtitle>
    </div>
    <TextBody className="mt-6" size={3}>{ productDescription }</TextBody>
  </div>
);

export default FreshProductCarouselCard;
