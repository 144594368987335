import { FC } from "react";

import { truncateString } from "apps/website/utils/website/truncateString";

import Image from "../../base/Image/Image";
import Logo from "../../base/Icon/Logo/Logo";
import TextControl from "../../base/Text/TextControl/TextControl";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";

import { COMPETITOR_DATA } from "./constants";
import { BrandWithoutExcluded } from "./brands";

export interface IngredientComparisonCardProps {
  competitorName: BrandWithoutExcluded;
  originalKKPricePerDay: string;
  trialKKPricePerDay: string;
  numberOfCats: number;
}

const IngredientComparisonCard: FC<IngredientComparisonCardProps> = ({
  competitorName,
  originalKKPricePerDay,
  trialKKPricePerDay,
  numberOfCats = 1,

}) => {

  const competitor = COMPETITOR_DATA[competitorName];

  if (!competitor) {
    return null;
  }

  const truncatedCompetitorIngredients = truncateString(competitor.ingredients, 160);

  const competitorPricePerDay = parseFloat(
    ((parseFloat(competitor.pricePerDay) || 0) * numberOfCats).toFixed(2),
  );

  return (

    <div className="flex items-start h-[450px] md:h-[500px] ">
      { /* KatKin Card */ }
      <div className="flex flex-col items-center justify-center bg-white border-black border-[3px] p-4 w-28  transform -rotate-[1.412deg] box-content z-10 translate-y-[12.5%]">

        { /* KatKin Cluck Image */ }
        <div className="mb-2 w-[96px] h-[100px] transform -rotate-[1.015deg]">
          <Image image={{ src: "/images/products/cluck.webp", height: 100, width: 96 }} alt="KatKin Cluck" />
        </div>

        { /* KatKin Logo */ }
        <Logo className="transform -rotate-1"/>

        { /* KatKin Product Name */ }
        <div className="bg-brand p-1 mb-2 tranform -rotate-1">
          <TextControl size={3} align={"center"} >CLUCK</TextControl>
        </div>

        { /* KatKin Price and Ingredients */ }
        <div className="space-y-1 ">
          <TextSubtitle size={3} align={"center"}>{ `${trialKKPricePerDay}/day` }</TextSubtitle>
          <TextBody size={4} align={"center"} className="line-through text-[#7B7979]">  { originalKKPricePerDay || "" } </TextBody>
          <TextBody size={3} align={"center"} className="flex-1">Human-quality meat <span className="font-bold">(100%)</span>, KatKin Nutrient Mix</TextBody>
        </div>

      </div>

      { /* Competitor Card */ }
      <div className="relative flex flex-col items-center justify-start overflow-hidden h-fit border-black border-2  w-[150px] bg-outline-grey px-4 py-3 transform rotate-[1.444deg] translate-x-[-12px] translate-y-[25%] box-content z-0 ">

        { /* Competitor Image */ }
        <div className="w-[72px] h-[72px] mb-2 transform rotate-1">
          <Image
            image={{ src: "/images/competitors/products/default-product.webp", height: 72, width: 72 }}
            alt="Default Competitor Product"
            className="object-contain w-full h-full"
          />
        </div>

        { /* Competitor Name */ }
        <TextSubtitle size={2} align={"center"}>{ competitor.name }</TextSubtitle>

        { /* Competitor Product Name */ }
        <div className="bg-white p-1 my-2 ">
          <TextControl size={3} align={"center"} whitespace={"balance"} className="uppercase">{ competitor.productName }</TextControl>
        </div>

        { /* Competitor Price and Ingredients */ }
        <div className="space-y-2 ">
          <TextSubtitle size={3} align={"center"}>{ `£${competitorPricePerDay}/day` }</TextSubtitle>
          <TextBody size={4} align={"center"} className="flex-1">{ truncatedCompetitorIngredients }</TextBody>
        </div>
      </div>

    </div>

  );
};

export default IngredientComparisonCard;
