import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";

import useModal from "apps/website/hooks/useModal";
import { Theme } from "apps/website/maps/Theme.map";
import useFocusTrap from "apps/website/hooks/useFocusTrap";
import cx from "apps/website/utils/misc/cx";

import Section from "../../layout/Section/Section";
import { getSectionSize } from "../../layout/Section/Section.utils";
import Button from "../../base/Button/Button";
import Modal from "../../feature/Modal/Modal";
import Icon from "../../base/Icon/Icon";
import TextControl from "../../base/Text/TextControl/TextControl";
import TextBody from "../../base/Text/TextBody/TextBody";
import TextTitle from "../../base/Text/TextTitle/TextTitle";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import IngredientComparisonCard from "../../feature/IngredientComparisonCard/IngredientComparisonCard";
import {
  BrandWithoutExcluded,
} from "../../feature/IngredientComparisonCard/brands";

import { MODAL_COMPARISON_DATA } from "./constants";

export interface ISectionIngredientComparisonModalProps {
  brand: BrandWithoutExcluded
  originalKKPricePerDay: string;
  trialKKPricePerDay: string;
  numberOfCats: number;
  theme?: Theme;
  containerClassName?: string;

}

const SectionIngredientComparisonModal = ({ brand, theme = "default", originalKKPricePerDay, trialKKPricePerDay, numberOfCats = 1, containerClassName }: ISectionIngredientComparisonModalProps) => {
  const posthog = usePostHog();
  const { modalId, modalTitleId, modalIsOpen, modalButtonId, setModalIsOpen } = useModal(false);
  const { initFocusTrap, destroyFocusTrap } = useFocusTrap();

  useEffect(() => {
    if (modalIsOpen) {
      initFocusTrap(modalId);
    } else {
      destroyFocusTrap(modalButtonId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ modalIsOpen ]);

  const handleOpenModal = () => {
    setModalIsOpen(true);
    posthog.capture("user_action_custom", {
      type: "ingredient_comparison",
    });
  };

  const modalData = MODAL_COMPARISON_DATA[brand];

  if (!modalData) {
    return null;
  }

  const showComparisonDetails = modalData.modalComparisonPrice.trim() !== "";

  return (
    <Section component={SectionIngredientComparisonModal.name} theme={theme} size={getSectionSize(theme)}>

      { /* Modal Button */ }
      <div id={modalButtonId} onClick={handleOpenModal} className={cx(`bg-blue w-full md:w-1/2 py-2 px-3 pt-2 flex items-center mt-2 relative cursor-pointer ${containerClassName}`)} >
        <div className="absolute -top-2 right-[10%] w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-blue"></div>
        <TextSubtitle size={4} className="text-white underline ">Compare this to their current food</TextSubtitle>
        <Icon icon="chevronRight" color="secondary" className="w-5 h-5"/>

      </div>

      <Modal
        component="SectionIngredientComparisonModalWindow"
        id={ modalId }
        isOpen={ modalIsOpen }
        onClose={ () => setModalIsOpen(false)}
        modalTitleId={ modalTitleId }
        theme="light-grey"
        size="md"
        gap="none"
        customChild={true}
      >
        <div className="flex flex-col max-h-[75vh] relative items-center bg-light-grey">
          { /* Close button */ }
          <div className="w-full sticky top-0 h-full flex items-center justify-end px-6">
            <div className={" bg-white h-8 w-8 rounded-full hover:opacity-50 transition-opacity duration-300 opacity-100 cursor-pointer z-20 mt-2"}
              onClick={() => setModalIsOpen(false)}>
              <Icon icon="cross" size="small" color="default" />
            </div>
          </div>

          { /* Main Content */ }
          <div className=" px-3 pt-8 pb-6 flex flex-col items-center overflow-y-auto space-y-6">
            { /* Meat Comparison Title and Subtitles */ }
            <div className="flex flex-col items-center relative">
              { showComparisonDetails ? (
                <>
                  <div className="bg-black px-1 py-0.5 mb-1">
                    <TextTitle size={2} className="uppercase text-white"> { `${parseFloat(modalData.modalComparisonPrice).toFixed(1)}x` }</TextTitle>
                  </div>

                  <div className="mb-2 max-w-[80%]">
                    <TextSubtitle size={1} align={"center"} whitespace={"balance"}>more real meat when you switch to KatKin</TextSubtitle>
                  </div>

                  <div>
                    <TextSubtitle size={2} align={"center"} whitespace={"balance"}>Check out the ingredient labels</TextSubtitle>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-black px-1 py-0.5 mb-1">
                    <TextTitle size={2} className="uppercase text-white">{ "100% REAL MEAT" }</TextTitle>
                  </div>

                  <div className="mb-2 max-w-[80%]">
                    <TextSubtitle size={1} align="center" whitespace="balance">
                  with all the nutrients they need, when you switch to KatKin
                    </TextSubtitle>
                  </div>
                </>
              ) }

              { /* Disclaimer */ }
              <TextBody size={4} whitespace={"balance"} align={"center"} className="max-w-[80%] mt-2 ">
                *Calculation based on daily requirements for a 4kg cat. Prices accurate as of January 2025
              </TextBody>

            </div>

            { /* Ingredients Comparison Components */ }
            <div className="h-[450px] md:h-[500px] relative ">
              { showComparisonDetails && (<div className="absolute -top-4 right-1/4 translate-x-10 transfrom scale-x-[-1] -rotate-[25deg]">
                <Icon icon="arrowSwoosh" size="large" color="default" />
              </div>) }
              <IngredientComparisonCard
                competitorName={brand}
                originalKKPricePerDay={originalKKPricePerDay}
                trialKKPricePerDay={trialKKPricePerDay}
                numberOfCats={numberOfCats}
              />
            </div>

          </div>
          <div className=" sticky bottom-0 py-4 px-6 w-full flex justify-center items-center">
            <Button onClick={() => setModalIsOpen(false)} design="line" ><TextControl size={4} weight={"bold"} className="uppercase">
                Return to pricing
            </TextControl></Button>
          </div>

        </div>

      </Modal>
    </Section>
  );
};

export default SectionIngredientComparisonModal;
