import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ISummaryItem } from "@forms/schema";
import { useAPI } from "apps/website/hooks/useAPI";
import { IMealPlanTypeResource } from "@api-clients/customer";
import { MealPlanType } from "libs/api/customer/src/lib/types/cats";
import { PouchSize } from "@./cat-calculations";
import { LIST_FORMATTER } from "apps/website/utils/misc/arrays";
import SectionArrow from "apps/website/components/section/SectionDownArrow/SectionArrow";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import Icon from "apps/website/components/base/Icon/Icon";
import TextControl from "apps/website/components/base/Text/TextControl/TextControl";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";

import { ISummaryDetailedFutureSaving } from "../types";

const SummaryDetailedFutureSaving: FC<ISummaryDetailedFutureSaving> = ({ cats }) => {
  const api = useAPI();
  const [ mealPlans, setMealPlans ] = useState<IMealPlanTypeResource[]>();

  useEffect(() => {
    const effectGetMealPlans = async () => {
      const plans = await api.Customer.getAvailableMealPlanTypes();
      setMealPlans(plans);
    };
    void effectGetMealPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseComparisonRecipeCost = useMemo(() => {
    const DEFAULT_PLAN: MealPlanType = "Plan28x28";
    const DEFAULT_TRAY_SIZE: PouchSize = "B";

    return mealPlans?.find((plan) => plan.planType === DEFAULT_PLAN)?.traySize[DEFAULT_TRAY_SIZE].price;
  }, [ mealPlans ]);

  const catsThatQualifyForFutureSavings = useMemo((): ISummaryItem[] | undefined => {
    if (!cats.length || !baseComparisonRecipeCost) return;
    const FUTURE_SAVINGS_QUALIFYING_POUCHES: PouchSize[] = [ "C", "D", "E", "F" ];
    const qualifyingKittens = cats.filter((cat) => (
      cat.isPredictedToSaveInFuture &&
      cat.pouchSize &&
      FUTURE_SAVINGS_QUALIFYING_POUCHES.includes(cat.pouchSize)
    ));
    if (qualifyingKittens.length) return qualifyingKittens;

  }, [ cats, baseComparisonRecipeCost ]);

  const collectiveCatGender = useMemo((): string => {
    if (catsThatQualifyForFutureSavings?.length === 1) return catsThatQualifyForFutureSavings[0].catGender === "BOY" ? "his" : "her";
    return "their";
  }, [ catsThatQualifyForFutureSavings ]);

  const title = useMemo((): string => {
    const catNames = catsThatQualifyForFutureSavings?.map((cat) => cat.catName as string).filter(
      (catName) => typeof catName === "string",
    );
    const catList = !catNames?.length ? "" : LIST_FORMATTER.format(catNames);

    return `You'll pay less as ${catList} grow${catNames?.length === 1 ? "s" : ""}`;

  }, [ catsThatQualifyForFutureSavings ]);

  const getPercentageSaving = useCallback((subscriptionCost: number | undefined): string => {
    if (!baseComparisonRecipeCost || !subscriptionCost) return "";
    if (baseComparisonRecipeCost > subscriptionCost) return "";

    return `${(Math.round(((subscriptionCost - baseComparisonRecipeCost) / subscriptionCost) * 100))}%`;

  }, [ baseComparisonRecipeCost ]);

  return (
    <>
      { (catsThatQualifyForFutureSavings) ? (
        <>
          <SectionArrow backgroundTheme="light" arrowTheme="brand" size="xs" direction="UP" justify="end" className="mr-8 lg:mr-6" />
          <div className={ `p-4 flex space-x-4 ${themeRootClassMap.brand}` } data-theme="brand">
            <div>
              <Icon icon="catProfileKitten" size="medium" />
            </div>
            <div>
              <TextControl size={ 2 }>{ title }</TextControl>
              <Spacer size="sm" />
              <TextBody size={ 3 }>Because kittens need more food than adult cats.
                So expect to save { catsThatQualifyForFutureSavings?.map(
                (cat, index) => (
                  <><TextBody size={ 3 } tag="span" className="font-bold">{ getPercentageSaving(cat.ongoingPriceRaw) } a month once { cat.catName } turns 1 year old</TextBody>{
                    index === catsThatQualifyForFutureSavings.length - 1 ?
                      <></> :
                      index === catsThatQualifyForFutureSavings.length - 2 ?
                        <> and </> :
                        <>, </>
                  }</>),
              ) }. You&apos;ll just need to update { collectiveCatGender } weight.</TextBody>
            </div>
          </div>
        </>
      ) : null }
    </>
  );
};

export default SummaryDetailedFutureSaving;
