import {
  BrandEnum,
  BrandWithoutExcluded,
} from "../../feature/IngredientComparisonCard/brands";

export interface ModalComparisonData {
  modalComparisonPrice: string;
}

export const MODAL_COMPARISON_DATA: Record<BrandWithoutExcluded, ModalComparisonData> = {
  [BrandEnum.FELIX]: { modalComparisonPrice: "12.5" },
  [BrandEnum.WHISKAS]: { modalComparisonPrice: "25.0" },
  [BrandEnum.GOURMET]: { modalComparisonPrice: "25.0" },
  [BrandEnum.SHEBA]: { modalComparisonPrice: "25.0" },
  [BrandEnum.PURINA_ONE]: { modalComparisonPrice: "25.0" },
  [BrandEnum.APPLAWS]: { modalComparisonPrice: "1.33" },
  [BrandEnum.ALMO_NATURE]: { modalComparisonPrice: "1.82" },
  [BrandEnum.AVA]: { modalComparisonPrice: "3.33" },
  [BrandEnum.BLINK]: { modalComparisonPrice: "1.67" },
  [BrandEnum.BURNS]: { modalComparisonPrice: "2.56" },
  [BrandEnum.CARNILOVE]: { modalComparisonPrice: "1.82" },
  [BrandEnum.CRAVE]: { modalComparisonPrice: "2.08" },
  [BrandEnum.EDGARD_COOPER]: { modalComparisonPrice: "1.89" },
  [BrandEnum.ENCORE]: { modalComparisonPrice: "1.33" },
  [BrandEnum.FERINGA]: { modalComparisonPrice: "1.45" },
  [BrandEnum.FRESHPET]: { modalComparisonPrice: "1.33" },
  [BrandEnum.GO_CAT]: { modalComparisonPrice: "25.0" },
  [BrandEnum.HARRINGTONS]: { modalComparisonPrice: "3.125" },
  [BrandEnum.HILLS]: { modalComparisonPrice: "3.23" },
  [BrandEnum.IAMS]: { modalComparisonPrice: "10.0" },
  [BrandEnum.JAMES_WELLBELOVED]: { modalComparisonPrice: "2.46" },
  [BrandEnum.LILYS_KITCHEN]: { modalComparisonPrice: "1.89" },
  [BrandEnum.MEOWING_HEADS]: { modalComparisonPrice: "1.47" },
  [BrandEnum.NATURES_MENU]: { modalComparisonPrice: "1.43" },
  [BrandEnum.ORIJEN]: { modalComparisonPrice: "1.82" },
  [BrandEnum.PURR_MIAOW]: { modalComparisonPrice: "1.33" },
  [BrandEnum.ROYAL_CANIN]: { modalComparisonPrice: "" },
  [BrandEnum.REPUBLIC_OF_CATS]: { modalComparisonPrice: "25.0" },
  [BrandEnum.SCRUMBLES]: { modalComparisonPrice: "1.67" },
  [BrandEnum.SUPERMARKET_BRAND]: { modalComparisonPrice: "25.0" },
  [BrandEnum.THRIVE]: { modalComparisonPrice: "1.33" },
  [BrandEnum.TOMOJO]: { modalComparisonPrice: "3.03" },
  [BrandEnum.UNTAMED]: { modalComparisonPrice: "1.59" },
};
