import {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";

import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image from "apps/website/components/base/Image/Image";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Icon from "apps/website/components/base/Icon/Icon";

import Modal from "../Modal";

interface IProcessingPaymentModal {
  phrases: string[];
  processingComplete: boolean;
}

const ProcessingPaymentModal: FC<IProcessingPaymentModal> = ({ phrases, processingComplete }) => {

  const [ activePhrase, setActivePhrase ] = useState(0);

  const loopPhrases = useCallback((nextStep: number) => {
    setTimeout(() => {
      const currStep = phrases.length <= nextStep ? 0 : nextStep;
      setActivePhrase(currStep);
      loopPhrases(currStep + 1);
    }, 3000);
  }, [ phrases ]);

  useEffect(() => {
    loopPhrases(1);
  }, []);

  return (
    <Modal
      component="PaymentProcessingModal"
      id="paymentProcessingModal"
      size="md"
      modalTitleId="paymentProcessingModal"
      onClose={() => console.log("paymentProcessingModal closed")}
      isOpen={true}
      hideCloseButton={true}
      borderRadius="rounded"
    >
      <div className="px-4 py-6">
        <Grid>
          <Column align="center">
            <Text tag="h2" display="subtitle" size={legacySizeCollectionMap.titleSm} align="center">We&apos;re processing your payment</Text>
            <Spacer size="md" />
            <Text size={legacySizeCollectionMap.titleXs} align="center">(Please don&apos;t close or refresh this page)</Text>
            <Spacer size="lg" />
            <Image
              className="max-w-[200px]"
              image={{
                src: "/images/checkout/cooking-cat.webp",
                width: 221,
                height: 220,
              }}
              alt="Processing order"
            />
            <Spacer size="lg" />
            { processingComplete ? (
              <div className="flex space-x-4 justify-center items-center">
                <Icon icon="tickInverted" size="medium" />
                <Text display="subtitle" size={{ default: "lg", lg: "4xl" }} align="center">All done now</Text>
              </div>
            ) : (
              <Text display="subtitle" size={{ default: "lg", lg: "4xl" }} align="center">{ phrases[activePhrase] }</Text>
            ) }
          </Column>
        </Grid>
      </div>
    </Modal>
  );
};

export default ProcessingPaymentModal;
